import React from 'react'
import { Provider } from 'react-redux'
import { Web3ReactProvider } from '@web3-react/core'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { LanguageProvider } from 'contexts/Localization'
import { getLibrary } from 'utils/web3React'
import { ToastsProvider } from 'contexts/ToastsContext'
import { ThemeContextProvider } from 'contexts/ThemeContext'

import store from 'state'
const Providers: React.FC = ({ children }) => {

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Provider store={store}>
                <ToastsProvider>
                <ThemeContextProvider>
                    <LanguageProvider>
                        <RefreshContextProvider>
                            {children}
                        </RefreshContextProvider>
                    </LanguageProvider>
                    </ThemeContextProvider>
                </ToastsProvider>
            </Provider>
        </Web3ReactProvider>
    )
}
export default Providers