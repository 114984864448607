import React, { useEffect, useState, useRef, useMemo } from 'react'
import multicall from 'utils/multicall'
import erc20 from 'config/abi/erc20.json'
import BigNumber from 'bignumber.js';
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import tokens from 'config/constants/tokens'
import Axios from "axios";
import { constant, parseInt } from 'lodash'
import { token2 } from 'config/constants/token2';
import { token3 } from 'config/constants/token3';
import CountUp, { useCountUp } from 'react-countup';

export default function Stat(props) {

    const [balances, setBalance] = useState([])
    const [balances2, setBalance2] = useState([])
    const total = props.data
    const select = props.addcount
    const [symbol, setSymbol] = useState(token3)

    // add function sum all balance from wallet
    const sumAllBalances = async () => {
        console.log('tokenwallet',props.data[select]);
            const calls1 = token3.map((farm) => {
                return {
                    address: farm.address[56],
                    name: 'totalSupply',
                }
            })
        try {
            const resBalance = await multicall(erc20, calls1)
          
            const parsedTokenBalances = resBalance.map((tokenBalance,index) => {
                return{symbol:symbol[index].symbol, total:new BigNumber(tokenBalance).toJSON()  } 
            })

                setBalance2(parsedTokenBalances);
                   
                // console.log(parsedTokenBalances); // show coin address
            
            await Axios.post('https://api.dev.vassanatoken.com/totalsupply_api.php', { address: parsedTokenBalances }).then((res)=>{
            // await Axios.post('http://localhost/api/totalsupply_api.php', { address: parsedTokenBalances }).then((res)=>{
            // await Axios.post('https://api.dev.vemo.world/totalsupply_api.php', { address: parsedTokenBalances }).then((res)=>{

                console.log(res)                
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
    
        sumAllBalances()
    
    }, [])


    const [wallet, set_wallet] = useState(0);
    function Get_wallet() {
        Axios.get('https://api.dev.vassanatoken.com/board_api.php').then((res)=>{set_wallet(res.data.wallet_total)});
        // Axios.get('http://localhost/api/board_api.php').then((res)=>{set_wallet(res.data.wallet_total)});
        // Axios.get('https://api.dev.vemo.world/board_api.php').then((res)=>{set_wallet(res.data.wallet_total)});
    }
    useEffect(() => {
        Get_wallet()
    }, [wallet,props.data])


    const [totalSupply, total_track] = useState(0);
    function Total_track_value() {
        Axios.get('https://api.dev.vassanatoken.com/totalsupply_api.php').then((res)=>{total_track(res.data.total_price)});
        // Axios.get('http://localhost/api/totalsupply_api.php').then((res)=>{total_track(res.data.total_price)});
        // Axios.get('https://api.dev.vemo.world/totalsupply_api.php').then((res)=>{total_track(res.data.total_price)});
    }
    useMemo(() => {
        Total_track_value()
    }, [])


    // useEffect(() => {
    //     if (total) {
    //         sumAllBalances()
    //     }
    //     console.log(total);
    // }, [total,props.addcount,balances2])

    return (
        <div className=''>
            <div className='rounded-3  bg-box p-3'>
                <div className='d-flex flex-row justify-content-center'><img style={{maxWidth:'50px'}} src="images/Icon-Home-Total.png" alt="" /></div>
                <div className='d-flex flex-column text-center'>
                    {/* <h1 className='fs-3'>${totalSupply}</h1> */}

                    <CountUp
                        start={0}
                        end={totalSupply}
                        duration={3}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="$"
                        suffix=""
                        onStart={() => console.log('Started!')}
                        onEnd={() => console.log('Ended!')}
                        
                    >
                    {({ countUpRef, start}) => (
                        <div>
                            <h1 className='fs-3'><span ref={countUpRef}></span></h1>
                        </div>
                    )}
                    </CountUp>

                </div>
                <div className='d-flex flex-column text-center mt-1'>
                <h2 style={{color:'rgba(255, 253, 253, 0.651) '}}>Total tracked value on VSNTassetsboard </h2>
                <p className='' style={{fontSize:'0.7rem',color:'rgba(255, 253, 253, 0.651)'}}>We help you to track your investment on bsc network check your investment now !</p>
                </div>     
            </div>

            <div className='mt-3 '>
                <div className='d-flex flex-row ' style={{columnGap:'5px'}}> 
               <div className=' rounded-3 bg-box p-3 ' style={{width:'50%'}}>
               <div className='d-flex flex-row justify-content-center'><img style={{maxWidth:'50px'}} src="images/Icon-Home-Tracked.png" alt="" /></div>
               <div className='d-flex flex-column text-center'>
                   <h1 className='fs-3'> - </h1>

                </div>
                <div className='d-flex flex-column text-center mt-1'>
                <h2 style={{color:'rgba(255, 253, 253, 0.651) '}}>Tracked value in farm</h2>
                </div>
               </div>

               <div className='rounded-3 bg-box p-3' style={{width:'50%'}}>
               <div className='d-flex flex-row justify-content-center'><img style={{maxWidth:'50px'}} src="images/Icon-Home-Wallets.png" alt=""/></div>
               <div className='d-flex flex-column text-center'>
                   {/* <h1 className='fs-3'>{wallet}</h1> */}

                   <CountUp
                        start={0}
                        end={wallet}
                        duration={3}
                        separator=","
                        decimals={0}
                        decimal=""
                        prefix=""
                        suffix=""
                        onStart={() => console.log('Started!')}
                        onEnd={() => console.log('Ended!')}
                    >
                    {({ countUpRef, start }) => (
                        <div>
                            <h1 className='fs-3'><span ref={countUpRef}></span></h1>
                        </div>
                    )}
                    </CountUp>

               </div>
                <div className='d-flex flex-column text-center mt-1'>
                <h2 style={{color:'rgba(255, 253, 253, 0.651)', wordWrap:'break-word'}}>Wallets on VSNTassetsboard</h2>
                </div>
               </div>
               </div>
            </div>
        </div>
    )
}