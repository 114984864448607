import React from 'react';
import './style/app.css';
import BigNumber from 'bignumber.js'
import { ResetCSS } from '@pancakeswap/uikit'
import Menu from './components/Menu'
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import history from './routerHistory'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 10,
})
function App() {




  return (
    <Router history={history}>
      <ResetCSS />
      
      <Menu>
        <Switch>
          <Route path="/" exact>
            <Home/>
     
          </Route>
         
        </Switch>
      </Menu>
    </Router>



  );
}

export default App;
