const tokens = {
    bnb: {
      symbol: 'BNB',
      projectLink: 'https://www.binance.com/',
    },
    cake: {
      symbol: 'CAKE',
      address: {
        56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
        97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },
    dop: {
      symbol: 'DOP',
      address: {
        56: '0x844FA82f1E54824655470970F7004Dd90546bB28',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://dopple.finance/',
    },
    dolly: {
      symbol: 'DOLLY',
      address: {
        56: '0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://dopple.finance/',
    },
    txl: {
      symbol: 'TXL',
      address: {
        56: '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://tixl.org/',
    },
    cos: {
      symbol: 'COS',
      address: {
        56: '0x96Dd399F9c3AFda1F194182F71600F1B65946501',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.contentos.io/',
    },
    bunny: {
      symbol: 'BUNNY',
      address: {
        56: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://pancakebunny.finance/',
    },
    alice: {
      symbol: 'ALICE',
      address: {
        56: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
        97: '',
      },
      decimals: 6,
      projectLink: 'https://www.myneighboralice.com/',
    },
    for: {
      symbol: 'FOR',
      address: {
        56: '0x658A109C5900BC6d2357c87549B651670E5b0539',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.for.tube/home',
    },
    bux: {
      symbol: 'BUX',
      address: {
        56: '0x211FfbE424b90e25a15531ca322adF1559779E45',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://getbux.com/bux-crypto/',
    },
    nuls: {
      symbol: 'NULS',
      address: {
        56: '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://www.nuls.io/',
    },
    belt: {
      symbol: 'BELT',
      address: {
        56: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://beta.belt.fi/',
    },
    ramp: {
      symbol: 'RAMP',
      address: {
        56: '0x8519EA49c997f50cefFa444d240fB655e89248Aa',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://rampdefi.com/',
    },
    bfi: {
      symbol: 'BFI',
      address: {
        56: '0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://bearn.fi/',
    },
    dexe: {
      symbol: 'DEXE',
      address: {
        56: '0x039cB485212f996A9DBb85A9a75d898F94d38dA6',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://dexe.network/',
    },
    bel: {
      symbol: 'BEL',
      address: {
        56: '0x8443f091997f06a61670B735ED92734F5628692F',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://bella.fi/',
    },
    tpt: {
      symbol: 'TPT',
      address: {
        56: '0xECa41281c24451168a37211F0bc2b8645AF45092',
        97: '',
      },
      decimals: 4,
      projectLink: 'https://www.tokenpocket.pro/',
    },
    watch: {
      symbol: 'WATCH',
      address: {
        56: '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://yieldwatch.net/',
    },
    xmark: {
      symbol: 'xMARK',
      address: {
        56: '0x26A5dFab467d4f58fB266648CAe769503CEC9580',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://benchmarkprotocol.finance/',
    },
    bmxx: {
      symbol: 'bMXX',
      address: {
        56: '0x4131b87F74415190425ccD873048C708F8005823',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://multiplier.finance/',
    },
    iotx: {
      symbol: 'IOTX',
      address: {
        56: '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://iotex.io/',
    },
    bor: {
      symbol: 'BOR',
      address: {
        56: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.boringdao.com/',
    },
    bopen: {
      symbol: 'bOPEN',
      address: {
        56: '0xF35262a9d427F96d2437379eF090db986eaE5d42',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://opendao.io/',
    },
    dodo: {
      symbol: 'DODO',
      address: {
        56: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://dodoex.io/',
    },
    swingby: {
      symbol: 'SWINGBY',
      address: {
        56: '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://swingby.network/',
    },
    bry: {
      symbol: 'BRY',
      address: {
        56: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://berrydata.co/',
    },
    zee: {
      symbol: 'ZEE',
      address: {
        56: '0x44754455564474A89358B2C2265883DF993b12F0',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://zeroswap.io/',
    },
    swgb: {
      symbol: 'SWGb',
      address: {
        56: '0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://swirgepay.com/',
    },
    swg: {
      symbol: 'SWG',
      address: {
        56: '0xe792f64C582698b8572AAF765bDC426AC3aEfb6B',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://swirgepay.com/',
    },
    sfp: {
      symbol: 'SFP',
      address: {
        56: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.safepal.io/',
    },
    lina: {
      symbol: 'LINA',
      address: {
        56: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://linear.finance/',
    },
    lit: {
      symbol: 'LIT',
      address: {
        56: '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.litentry.com/',
    },
    hget: {
      symbol: 'HGET',
      address: {
        56: '0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731',
        97: '',
      },
      decimals: 6,
      projectLink: 'https://www.hedget.com/',
    },
    bdo: {
      symbol: 'BDO',
      address: {
        56: '0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://bdollar.fi/',
    },
    egld: {
      symbol: 'EGLD',
      address: {
        56: '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://elrond.com/',
    },
    ust: {
      symbol: 'UST',
      address: {
        56: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    wsote: {
      symbol: 'wSOTE',
      address: {
        56: '0x541E619858737031A1244A5d0Cd47E5ef480342c',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://soteria.finance/#/',
    },
    front: {
      symbol: 'FRONT',
      address: {
        56: '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://frontier.xyz/',
    },
    helmet: {
      symbol: 'Helmet',
      address: {
        56: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.helmet.insure/',
    },
    btcst: {
      symbol: 'BTCST',
      address: {
        56: '0x78650B139471520656b9E7aA7A5e9276814a38e9',
        97: '',
      },
      decimals: 17,
      projectLink: 'https://www.1-b.tc/',
    },
    bscx: {
      symbol: 'BSCX',
      address: {
        56: '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://bscex.org/',
    },
    ten: {
      symbol: 'TEN',
      address: {
        56: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.tenet.farm/',
    },
    balbt: {
      symbol: 'bALBT',
      address: {
        56: '0x72fAa679E1008Ad8382959FF48E392042A8b06f7',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://allianceblock.io/',
    },
    asr: {
      symbol: 'ASR',
      address: {
        56: '0x80D5f92C2c8C682070C95495313dDB680B267320',
        97: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    atm: {
      symbol: 'ATM',
      address: {
        56: '0x25E9d05365c867E59C1904E7463Af9F312296f9E',
        97: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    og: {
      symbol: 'OG',
      address: {
        56: '0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c',
        97: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    reef: {
      symbol: 'REEF',
      address: {
        56: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://reef.finance/',
    },
    ditto: {
      symbol: 'DITTO',
      address: {
        56: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://ditto.money/',
    },
    juv: {
      symbol: 'JUV',
      address: {
        56: '0xC40C9A843E1c6D01b7578284a9028854f6683b1B',
        97: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    psg: {
      symbol: 'PSG',
      address: {
        56: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
        97: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    vai: {
      symbol: 'VAI',
      address: {
        56: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://venus.io/',
    },
    wbnb: {
      symbol: 'wBNB',
      address: {
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },
    blink: {
      symbol: 'BLINK',
      address: {
        56: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
        97: '',
      },
      decimals: 6,
      projectLink: 'https://blink.wink.org',
    },
    unfi: {
      symbol: 'UNFI',
      address: {
        56: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://unifiprotocol.com',
    },
    twt: {
      symbol: 'TWT',
      address: {
        56: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://trustwallet.com/',
    },
    hard: {
      symbol: 'HARD',
      address: {
        56: '0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4',
        97: '',
      },
      decimals: 6,
      projectLink: 'https://hard.kava.io',
    },
    broobee: {
      symbol: 'bROOBEE',
      address: {
        56: '0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://roobee.io/',
    },
    stax: {
      symbol: 'STAX',
      address: {
        56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
        97: '',
      },
      decimals: 18,
      projectLink: 'http://stablexswap.com/',
    },
    nar: {
      symbol: 'NAR',
      address: {
        56: '0xA1303E6199b319a891b79685F0537D289af1FC83',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://narwhalswap.org/',
    },
    nya: {
      symbol: 'NYA',
      address: {
        56: '0xbFa0841F7a90c4CE6643f651756EE340991F99D5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://nyanswop.org/',
    },
    ctk: {
      symbol: 'CTK',
      address: {
        56: '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
        97: '',
      },
      decimals: 6,
      projectLink: 'https://www.certik.foundation/',
    },
    inj: {
      symbol: 'INJ',
      address: {
        56: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://injectiveprotocol.com/',
    },
    sxp: {
      symbol: 'SXP',
      address: {
        56: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://swipe.io/',
    },
    alpha: {
      symbol: 'ALPHA',
      address: {
        56: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://alphafinance.io/',
    },
    xvs: {
      symbol: 'XVS',
      address: {
        56: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://venus.io/',
    },
    sushi: {
      symbol: 'SUSHI',
      address: {
        56: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://sushi.com/',
    },
    comp: {
      symbol: 'COMP',
      address: {
        56: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://compound.finance/',
    },
    syrup: {
      symbol: 'SYRUP',
      address: {
        56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
        97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },
    bifi: {
      symbol: 'BIFI',
      address: {
        56: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://beefy.finance/',
    },
    dusk: {
      symbol: 'DUSK',
      address: {
        56: '0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://dusk.network/',
    },
    busd: {
      symbol: 'BUSD',
      address: {
        56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },
    eth: {
      symbol: 'ETH',
      address: {
        56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://ethereum.org/en/',
    },
    beth: {
      symbol: 'BETH',
      address: {
        56: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://ethereum.org/en/eth2/beacon-chain/',
    },
    mamzn: {
      symbol: 'mAMZN',
      address: {
        56: '0x3947B992DC0147D2D89dF0392213781b04B25075',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    mgoogl: {
      symbol: 'mGOOGL',
      address: {
        56: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    mnflx: {
      symbol: 'mNFLX',
      address: {
        56: '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    mtsla: {
      symbol: 'mTSLA',
      address: {
        56: '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    ltc: {
      symbol: 'LTC',
      address: {
        56: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://litecoin.org/',
    },
    usdc: {
      symbol: 'USDC',
      address: {
        56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.centre.io/usdc',
    },
    dai: {
      symbol: 'DAI',
      address: {
        56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://makerdao.com/',
    },
    ada: {
      symbol: 'ADA',
      address: {
        56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://www.cardano.org/',
    },
    band: {
      symbol: 'BAND',
      address: {
        56: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://bandprotocol.com/',
    },
    dot: {
      symbol: 'DOT',
      address: {
        56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://polkadot.network/',
    },
    eos: {
      symbol: 'EOS',
      address: {
        56: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://eos.io/',
    },
    link: {
      symbol: 'LINK',
      address: {
        56: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://chain.link/',
    },
    usdt: {
      symbol: 'USDT',
      address: {
        56: '0x55d398326f99059fF775485246999027B3197955',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://tether.to/',
    },
    btcb: {
      symbol: 'BTCB',
      address: {
        56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://bitcoin.org/',
    },
    xrp: {
      symbol: 'XRP',
      address: {
        56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://ripple.com/xrp/',
    },
    atom: {
      symbol: 'ATOM',
      address: {
        56: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://cosmos.network/',
    },
    yfii: {
      symbol: 'YFII',
      address: {
        56: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://dfi.money/#/',
    },
    xtz: {
      symbol: 'XTZ',
      address: {
        56: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://www.tezos.com/',
    },
    bch: {
      symbol: 'BCH',
      address: {
        56: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'http://bch.info/',
    },
    yfi: {
      symbol: 'YFI',
      address: {
        56: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://yearn.finance/',
    },
    uni: {
      symbol: 'UNI',
      address: {
        56: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://uniswap.org/',
    },
    fil: {
      symbol: 'FIL',
      address: {
        56: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://filecoin.io/',
    },
    bake: {
      symbol: 'BAKE',
      address: {
        56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.bakeryswap.org/',
    },
    burger: {
      symbol: 'BURGER',
      address: {
        56: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
        97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://burgerswap.org/',
    },
    bdigg: {
      symbol: 'bDIGG',
      address: {
        56: '0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://badger.finance/',
    },
    bbadger: {
      symbol: 'bBadger',
      address: {
        56: '0x1F7216fdB338247512Ec99715587bb97BBf96eae',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://badger.finance/',
    },
    trade: {
      symbol: 'TRADE',
      address: {
        56: '0x7af173F350D916358AF3e218Bdf2178494Beb748',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://unitrade.app/',
    },
    pnt: {
      symbol: 'PNT',
      address: {
        56: '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://ptokens.io/',
    },
    mir: {
      symbol: 'MIR',
      address: {
        56: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    pbtc: {
      symbol: 'pBTC',
      address: {
        56: '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://ptokens.io/',
    },
    lto: {
      symbol: 'LTO',
      address: {
        56: '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://ltonetwork.com/',
    },
    pcws: {
      symbol: 'pCWS',
      address: {
        56: '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://game.seascape.network/',
    },
    zil: {
      symbol: 'ZIL',
      address: {
        56: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
        97: '',
      },
      decimals: 12,
      projectLink: 'https://www.zilliqa.com/',
    },
    lien: {
      symbol: 'LIEN',
      address: {
        56: '0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://lien.finance/',
    },
    swth: {
      symbol: 'SWTH',
      address: {
        56: '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://switcheo.network/',
    },
    dft: {
      symbol: 'DFT',
      address: {
        56: '0x42712dF5009c20fee340B245b510c0395896cF6e',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.dfuture.com/home',
    },
    gum: {
      symbol: 'GUM',
      address: {
        56: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://gourmetgalaxy.io/',
    },
    dego: {
      symbol: 'DEGO',
      address: {
        56: '0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://bsc.dego.finance/home',
    },
    nrv: {
      symbol: 'NRV',
      address: {
        56: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://nerve.fi/',
    },
    easy: {
      symbol: 'EASY',
      address: {
        56: '0x7C17c8bED8d14bAccE824D020f994F4880D6Ab3B',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://easyfi.network/',
    },
    oddz: {
      symbol: 'ODDZ',
      address: {
        56: '0xCD40F2670CF58720b694968698A5514e924F742d',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://oddz.fi/',
    },
    hoo: {
      symbol: 'HOO',
      address: {
        56: '0xE1d1F66215998786110Ba0102ef558b22224C016',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://hoo.com/',
    },
    apys: {
      symbol: 'APYS',
      address: {
        56: '0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://apyswap.com/',
    },
    bondly: {
      symbol: 'BONDLY',
      address: {
        56: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.bondly.finance/',
    },
    tko: {
      symbol: 'TKO',
      address: {
        56: '0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.tokocrypto.com/',
    },
    itam: {
      symbol: 'ITAM',
      address: {
        56: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://itam.network/',
    },
    arpa: {
      symbol: 'ARPA',
      address: {
        56: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://arpachain.io/',
    },
    eps: {
      symbol: 'EPS',
      address: {
        56: '0xA7f552078dcC247C2684336020c03648500C6d9F',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://ellipsis.finance/',
    },
    jgn: {
      symbol: 'JGN',
      address: {
        56: '0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://jgndefi.com/',
    },
    tlm: {
      symbol: 'TLM',
      address: {
        56: '0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
        97: '',
      },
      decimals: 4,
      projectLink: 'https://alienworlds.io/',
    },
    perl: {
      symbol: 'PERL',
      address: {
        56: '0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://perlinx.finance/',
    },
    alpa: {
      symbol: 'ALPA',
      address: {
        56: '0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://bsc.alpaca.city/',
    },
    hzn: {
      symbol: 'HZN',
      address: {
        56: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://horizonprotocol.com/',
    },
    suter: {
      symbol: 'SUTER',
      address: {
        56: '0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://shield.suterusu.io/',
    },
    cgg: {
      symbol: 'CGG',
      address: {
        56: '0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://chainguardians.io/',
    },
    mix: {
      symbol: 'MIX',
      address: {
        56: '0xB67754f5b4C704A24d2db68e661b2875a4dDD197',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mixie.chainguardians.io/',
    },
    hakka: {
      symbol: 'HAKKA',
      address: {
        56: '0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://hakka.finance/',
    },
    xed: {
      symbol: 'XED',
      address: {
        56: '0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.exeedme.com/',
    },
    τbtc: {
      symbol: 'τBTC',
      address: {
        56: '0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://www.btcst.finance/',
    },
    alpaca: {
      symbol: 'ALPACA',
      address: {
        56: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.alpacafinance.org/',
    },
    dfd: {
      symbol: 'DFD',
      address: {
        56: '0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://dusd.finance/',
    },
    lmt: {
      symbol: 'LMT',
      address: {
        56: '0x9617857E191354dbEA0b714d78Bc59e57C411087',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://lympo.io/lmt/',
    },
    btt: {
      symbol: 'BTT',
      address: {
        56: '0x8595F9dA7b868b1822194fAEd312235E43007b49',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.bittorrent.com/',
    },
    trx: {
      symbol: 'TRX',
      address: {
        56: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://tron.network/',
    },
    win: {
      symbol: 'WIN',
      address: {
        56: '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://winklink.org/',
    },
    mcoin: {
      symbol: 'mCOIN',
      address: {
        56: '0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    math: {
      symbol: 'MATH',
      address: {
        56: '0xF218184Af829Cf2b0019F8E6F0b2423498a36983',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://mathwallet.org/',
    },
    kun: {
      symbol: 'KUN',
      address: {
        56: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://chemix.io/home',
    },
    qsd: {
      symbol: 'QSD',
      address: {
        56: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://chemix.io/home',
    },
    hyfi: {
      symbol: 'HYFI',
      address: {
        56: '0x9a319b959e33369C5eaA494a770117eE3e585318',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://hyfi.pro/#/',
    },
    oin: {
      symbol: 'OIN',
      address: {
        56: '0x658E64FFcF40D240A43D52CA9342140316Ae44fA',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://oin.finance/',
    },
    doge: {
      symbol: 'DOGE',
      address: {
        56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://dogecoin.com/',
    },
    fine: {
      symbol: 'FINE',
      address: {
        56: '0x4e6415a5727ea08aAE4580057187923aeC331227',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://refinable.com/',
    },
    one: {
      symbol: 'ONE',
      address: {
        56: '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.bigone.com/',
    },
    pmon: {
      symbol: 'PMON',
      address: {
        56: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://polkamon.com/',
    },
    hotcross: {
      symbol: 'HOTCROSS',
      address: {
        56: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.hotcross.com/',
    },
    τdoge: {
      symbol: 'τDOGE',
      address: {
        56: '0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://www.btcst.finance/',
    },
    btr: {
      symbol: 'BTR',
      address: {
        56: '0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.bitrue.com/',
    },
    ubxt: {
      symbol: 'UBXT',
      address: {
        56: '0xBbEB90cFb6FAFa1F69AA130B7341089AbeEF5811',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://upbots.com/',
    },
    wmass: {
      symbol: 'WMASS',
      address: {
        56: '0x7e396BfC8a2f84748701167c2d622F041A1D7a17',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://massnet.org/en/',
    },
    rfox: {
      symbol: 'RFOX',
      address: {
        56: '0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.redfoxlabs.io/',
    },
    xend: {
      symbol: 'XEND',
      address: {
        56: '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://xend.finance/',
    },
    cyc: {
      symbol: 'CYC',
      address: {
        56: '0x810EE35443639348aDbbC467b33310d2AB43c168',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://cyclone.xyz/',
    },
    chr: {
      symbol: 'CHR',
      address: {
        56: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
        97: '',
      },
      decimals: 6,
      projectLink: 'https://chromia.com/',
    },
    kalm: {
      symbol: 'KALM',
      address: {
        56: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://kalmar.io/',
    },
    deri: {
      symbol: 'DERI',
      address: {
        56: '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://deri.finance/#/index',
    },
    well: {
      symbol: 'WELL',
      address: {
        56: '0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.bitwellex.com/',
    },
    popen: {
      symbol: 'pOPEN',
      address: {
        56: '0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://opendao.io/',
    },
    ez: {
      symbol: 'EZ',
      address: {
        56: '0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://easyfi.network/',
    },
    vrt: {
      symbol: 'VRT',
      address: {
        56: '0x5F84ce30DC3cF7909101C69086c50De191895883',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://venus.io/',
    },
    tusd: {
      symbol: 'TUSD',
      address: {
        56: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.trueusd.com/',
    },
    mtrg: {
      symbol: 'MTRG',
      address: {
        56: '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.meter.io/',
    },
    ktn: {
      symbol: 'KTN',
      address: {
        56: '0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://kattana.io/',
    },
    qkc: {
      symbol: 'QKC',
      address: {
        56: '0xA1434F1FC3F437fa33F7a781E041961C0205B5Da',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://quarkchain.io/',
    },
    bcfx: {
      symbol: 'bCFX',
      address: {
        56: '0x045c4324039dA91c52C55DF5D785385Aab073DcF',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.confluxnetwork.org/',
    },
    mx: {
      symbol: 'MX',
      address: {
        56: '0x9F882567A62a5560d147d64871776EeA72Df41D3',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.mxc.com/',
    },
    ata: {
      symbol: 'ATA',
      address: {
        56: '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.ata.network/',
    },
    mbox: {
      symbol: 'MBOX',
      address: {
        56: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.mobox.io/#/',
    },
    boring: {
      symbol: 'BORING',
      address: {
        56: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.boringdao.com/',
    },
    marsh: {
      symbol: 'MARSH',
      address: {
        56: '0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://unmarshal.io/',
    },
    ampl: {
      symbol: 'AMPL',
      address: {
        56: '0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://www.ampleforth.org/',
    },
    o3: {
      symbol: 'O3',
      address: {
        56: '0xEe9801669C6138E84bD50dEB500827b776777d28',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://o3swap.com/',
    },
    hai: {
      symbol: 'HAI',
      address: {
        56: '0xaA9E582e5751d703F85912903bacADdFed26484C',
        97: '',
      },
      decimals: 8,
      projectLink: 'https://hacken.io/',
    },
    htb: {
      symbol: 'HTB',
      address: {
        56: '0x4e840AADD28DA189B9906674B4Afcb77C128d9ea',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.hotbit.io/',
    },
    avax: {
      symbol: 'AVAX',
      address: {
        56: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.avalabs.org/',
    },
    zec: {
      symbol: 'ZEC',
      address: {
        56: '0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://z.cash/',
    },
    coti: {
      symbol: 'COTI',
      address: {
        56: '0xAdBAF88B39D37Dc68775eD1541F1bf83A5A45feB',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    near: {
      symbol: 'NEAR',
      address: {
        56: '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://near.org/',
    },
    moonlight: {
      symbol: 'MOONLIGHT',
      address: {
        56: '0xB1CeD2e320E3f4C8e3511B1DC59203303493F382',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://moonlighttoken.com',
    },
    snx: {
      symbol: 'SNX',
      address: {
        56: '0x9ac983826058b8a9c7aa1c9171441191232e8404',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.synthetix.io/',
    },
    blz: {
      symbol: 'BLZ',
      address: {
        56: '0x935a544Bf5816E3A7C13DB2EFe3009Ffda0aCdA2',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    tape: {
      symbol: 'TAPE',
      address: {
        56: '0xf63400ee0420ce5b1ebdee0c942d7de1c734a41f',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.apetools.co/',
    },
    celr: {
      symbol: 'CELR',
      address: {
        56: '0x1f9f6a696c6fd109cd3956f45dc709d2b3902163',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://celer.network/',
    },
    shib: {
      symbol: 'SHIB',
      address: {
        56: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://shibatoken.com/',
    },
    crush: {
      symbol: 'CRUSH',
      address: {
        56: '0x0ef0626736c2d484a792508e99949736d0af807e',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    typh: {
      symbol: 'TYPH',
      address: {
        56: '0x4090e535F2e251F5F88518998B18b54d26B3b07c',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://typhoon.network/',
    },
    space: {
      symbol: 'SPACE',
      address: {
        56: '0xe486a69E432Fdc29622bF00315f6b34C99b45e80',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://aperocket.finance/',
    },
    feg: {
      symbol: 'FEG',
      address: {
        56: '0xacfc95585d80ab62f67a14c566c1b7a49fe91167',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://fegtoken.com/',
    },
    grand: {
      symbol: 'GRAND',
      address: {
        56: '0xee814f5b2bf700d2e843dc56835d28d095161dd9',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.thegrandbanks.finance/#/',
    },
    hifi: {
      symbol: 'HIFI',
      address: {
        56: '0x0A38bc18022b0cCB043F7b730B354d554C6230F1',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    gmr: {
      symbol: 'GMR',
      address: {
        56: '0x0523215DCafbF4E4aA92117d13C6985a3BeF27D7',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://www.gmr.finance/',
    },
    hero: {
      symbol: 'HERO',
      address: {
        56: '0x9b26e16377ad29a6ccc01770bcfb56de3a36d8b2',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.farmhero.io/',
    },
    foxy: {
      symbol: 'Foxy',
      address: {
        56: '0x4354a4f710182966e55ea30cfa807fa1b821a67b',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://foxynft.org/',
    },
    wyvern: {
      symbol: 'WYVERN',
      address: {
        56: '0x470862Af0cf8D27EBFE0Ff77B0649779C29186Db',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://wyverntoken.com/',
    },
    bog: {
      symbol: 'BOG',
      address: {
        56: '0xb09fe1613fe03e7361319d2a43edc17422f36b09',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://bogtools.io/',
    },
    scam: {
      symbol: 'Scam',
      address: {
        56: '0x00aa85e010204068b7CC2235800B2d8036bdbF2E',
        97: '',
      },
      decimals: 10,
      projectLink: 'https://scamily.io/',
    },
    twin: {
      symbol: 'TWIN',
      address: {
        56: '0x62907ad5c2d79e2a4f048a90ae2b49d062a773f3',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://twin.finance/',
    },
    lory: {
      symbol: 'LORY',
      address: {
        56: '0xcd5d75dbe75449a9021b6c570a41959eb571c751',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://app.yieldparrot.finance/#/',
    },
    fruit: {
      symbol: 'FRUIT',
      address: {
        56: '0x4ecfb95896660aa7f54003e967e7b283441a2b0a',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.fruitsadventures.com/',
    },
    skill: {
      symbol: 'SKILL',
      address: {
        56: '0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    gnt: {
      symbol: 'GNT',
      address: {
        56: '0xf750a26eb0acf95556e8529e72ed530f3b60f348',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.greentrusttoken.com/',
    },
    pacoca: {
      symbol: 'PACOCA',
      address: {
        56: '0x55671114d774ee99d653d6c12460c780a67f1d18',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://pacoca.io/',
    },
    newb: {
      symbol: 'NEWB',
      address: {
        56: '0x545f90dc35ca1e6129f1fed354b3e2df12034261',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.newb.farm/#/home',
    },
    bison: {
      symbol: 'BISON',
      address: {
        56: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    stars: {
      symbol: 'STARS',
      address: {
        56: '0xbd83010eb60f12112908774998f65761cf9f6f9a',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    caps: {
      symbol: 'CAPS',
      address: {
        56: '0xffba7529ac181c2ee1844548e6d7061c9a597df4',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    sista: {
      symbol: 'SISTA',
      address: {
        56: '0xca6d25c10dad43ae8be0bc2af4d3cd1114583c08',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://srnartgallery.com',
    },
    birb: {
      symbol: 'BIRB',
      address: {
        56: '0x82a479264b36104be4fdb91618a59a4fc0f50650',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://birb.com/',
    },
    axs: {
      symbol: 'AXS',
      address: {
        56: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://axieinfinity.com/',
    },
    dinop: {
      symbol: 'DINOP',
      address: {
        56: '0xea90dc6f64d18771ca1dac8098526a9082265b42',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://www.dinopark.fun/',
    },
    maru: {
      symbol: 'MARU',
      address: {
        56: '0x215f51990a0ca5aa360a177181f014e2da376e5e',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    pera: {
      symbol: 'PERA',
      address: {
        56: '0xb9d8592e16a9c1a3ae6021cddb324eac1cbc70d6',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://pera.finance/',
    },
    land: {
      symbol: 'LAND',
      address: {
        56: '0x9d986a3f147212327dd658f712d5264a73a1fdb0',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://landshare.io/',
    },
    pots: {
      symbol: 'POTS',
      address: {
        56: '0x3fcca8648651e5b974dd6d3e50f61567779772a8',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    sact: {
      symbol: 'SACT',
      address: {
        56: '0x1bA8c21c623C843Cd4c60438d70E7Ad50f363fbb',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://srnartgallery.com/',
    },
    guard: {
      symbol: 'GUARD',
      address: {
        56: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    bhc: {
      symbol: 'BHC',
      address: {
        56: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://billionhappiness.finance/',
    },
    cyt: {
      symbol: 'CYT',
      address: {
        56: '0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    foot: {
      symbol: 'FOOT',
      address: {
        56: '0x1c64fd4f55e1a3c1f737dfa47ee5f97eaf413cf0',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    nabox: {
      symbol: 'NABOX',
      address: {
        56: '0x755f34709e369d37c6fa52808ae84a32007d1155',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://nabox.io/',
    },
    babl: {
      symbol: 'BABI',
      address: {
        56: '0xec15a508a187e8ddfe572a5423faa82bbdd65120',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    banana: {
      symbol: 'BANANA',
      address: {
        56: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://apeswap.finance/',
    },
    brew: {
      symbol: 'BREW',
      address: {
        56: '0x790be81c3ca0e53974be2688cdb954732c9862e1',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://cafeswap.finance/',
    },
    gfce: {
      symbol: 'GFCE',
      address: {
        56: '0x5f136383e230f972739fae2e81e7e774afe64c66',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://jetfuel.finance/',
    },
    naut: {
      symbol: 'NAUT',
      address: {
        56: '0x5f136383e230f972739fae2e81e7e774afe64c66',
        97: '',
      },
      decimals: 9,
      projectLink: 'https://jetfuel.finance/',
    },
    bat: {
      symbol: 'BAT',
      address: {
        56: '0x101d82428437127bf1608f699cd651e6abf9766e',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://basicattentiontoken.org/',
    },
    iota: {
      symbol: 'IOTA',
      address: {
        56: '0xd944f1d1e9d5f9bb90b62f9d45e447d989580782',
        97: '',
      },
      decimals: 6,
      projectLink: 'https://www.iota.org/',
    },
    rocket: {
      symbol: 'Rocket',
      address: {
        56: '0x3ba5aee47bb7eae40eb3d06124a74eb89da8ffd2',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://rocketgame.vip/',
    },
    jdi: {
      symbol: 'JDI',
      address: {
        56: '0x0491648c910ad2c1afaab733faf71d30313df7fc',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.jdiyield.com/',
    },
    nuts: {
      symbol: 'NUTS',
      address: {
        56: '0x8893d5fa71389673c5c4b9b3cb4ee1ba71207556',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://squirrel.finance/',
    },
    swamp: {
      symbol: 'SWAMP',
      address: {
        56: '0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://swamp.finance/',
    },
    keyfi: {
      symbol: 'KEYFI',
      address: {
        56: '0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d',
        97: '',
      },
      decimals: 18,
      projectLink: '',
    },
    ont: {
      symbol: 'ONT',
      address: {
        56: '0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://ont.io/',
    },
    bscusd: {
      symbol: 'BSC-USD',
      address: {
        56: '0x55d398326f99059ff775485246999027b3197955',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.binance.com/en/trade/BNB_USDT',
    },
    bft: {
      symbol: 'BFT',
      address: {
        56: '0xa4f93159ce0a4b533b443c74b89967c60a5969f8',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://www.bitfresh.win/',
    },
    auto: {
      symbol: 'AUTO',
      address: {
        56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://autofarm.network/',
    },
    bxbtc: {
      symbol: 'bxBTC',
      address: {
        56: '0xab111d5948470ba73d98d66bbdf2798fbe093546',
        97: '',
      },
      decimals: 9,
      projectLink: '',
    },
    vbswap: {
      symbol: 'vBSWAP',
      address: {
        56: '0x4f0ed527e8a95ecaa132af214dfd41f30b361600',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://bsc.valuedefi.io/',
    },
    matic: {
      symbol: 'MATIC',
      address: {
        56: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://polygon.technology/',
    },
    aave: {
      symbol: 'AAVE',
      address: {
        56: '0xfb6115445bff7b52feb98650c87f44907e58f802',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://aave.com/',
    },
    etc: {
      symbol: 'ETC',
      address: {
        56: '0x3d6545b08693dae087e957cb1180ee38b9e3c25e',
        97: '',
      },
      decimals: 18,
      projectLink: 'https://ethereumclassic.org/',
    },
    ftm: {
      symbol: 'FTM',
      address: {
        56: '0xad29abb318791d579433d831ed122afeaf29dcfe',
        97: '',
      },
      decimals: 18,
      projectLink: 'http://fantom.foundation/',
    },


    /////////////////////////////eth mainnet////////////////////////
    weth: {
      symbol: 'WETH',
      address: {
        56: '',
        1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      },
      decimals: 18,
      projectLink: 'https://weth.io/',
    },
    bank: {
      symbol: 'BANK',
      address: {
        56: '',
        1: '0x2d94AA3e47d9D5024503Ca8491fcE9A2fB4DA198',
      },
      decimals: 18,
      projectLink: 'https://www.bankless.community/',
    },
    usdt_eth: {
      symbol: 'USDT',
      address: {
        56: '',
        1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      },
      decimals: 6,
      projectLink: 'https://tether.to/',
    },
    usdc_eth: {
      symbol: 'USDC',
      address: {
        56: '',
        1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      },
      decimals: 6,
      projectLink: 'https://www.centre.io/',
    },
    dai_eth: {
      symbol: 'DAI',
      address: {
        56: '',
        1: '0x6b175474e89094c44da98b954eedeac495271d0f',
      },
      decimals: 18,
      projectLink: 'https://makerdao.com/',
    },
    susd: {
      symbol: 'sUSD',
      address: {
        56: '',
        1: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
      },
      decimals: 18,
      projectLink: 'https://www.synthetix.io/',
    },
    comp_eth: {
      symbol: 'COMP',
      address: {
        56: '',
        1: '0xc00e94cb662c3520282e6f5717214004a7f26888',
      },
      decimals: 18,
      projectLink: 'https://compound.finance/',
    },
    lend: {
      symbol: 'LEND',
      address: {
        56: '',
        1: '0x80fb784b7ed66730e8b1dbd9820afd29931aab03',
      },
      decimals: 18,
      projectLink: 'https://aave.com/',
    },
    snx_eth: {
      symbol: 'SNX',
      address: {
        56: '',
        1: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
      },
      decimals: 18,
      projectLink: 'https://www.synthetix.io/',
    },
    uma: {
      symbol: 'UMA',
      address: {
        56: '',
        1: '0x04fa0d235c4abf4bcf4787af4cf447de572ef828',
      },
      decimals: 18,
      projectLink: 'https://umaproject.org/',
    },
    link_eth: {
      symbol: 'LINK',
      address: {
        56: '',
        1: '0x514910771af9ca656af840dff83e8264ecf986ca',
      },
      decimals: 18,
      projectLink: 'https://chain.link/',
    },
    band_eth: {
      symbol: 'BAND',
      address: {
        56: '',
        1: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
      },
      decimals: 18,
      projectLink: 'https://bandprotocol.com/',
    },
    ampl_eth: {
      symbol: 'AMPL',
      address: {
        56: '',
        1: '0xd46ba6d942050d489dbd938a2c909a5d5039a161',
      },
      decimals: 9,
      projectLink: 'https://www.ampleforth.org/',
    },
    yfi_eth: {
      symbol: 'YFI',
      address: {
        56: '',
        1: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
      },
      decimals: 18,
      projectLink: 'https://yearn.finance/',
    },
    sushi_eth: {
      symbol: 'SUSHI',
      address: {
        56: '',
        1: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
      },
      decimals: 18,
      projectLink: 'https://sushiswap.org/',
    },
    ren: {
      symbol: 'REN',
      address: {
        56: '',
        1: '0x408e41876cccdc0f92210600ef50372656052a38',
      },
      decimals: 18,
      projectLink: 'https://renproject.io/',
    },
    $based: {
      symbol: '$BASED',
      address: {
        56: '',
        1: '0x68a118ef45063051eac49c7e647ce5ace48a68a5',
      },
      decimals: 18,
      projectLink: 'https://based.money/',
    },
    srm: {
      symbol: 'SRM',
      address: {
        56: '',
        1: '0x476c5e26a75bd202a9683ffd34359c0cc15be0ff',
      },
      decimals: 6,
      projectLink: 'https://projectserum.com/',
    },
    yamv2: {
      symbol: 'YAMv2',
      address: {
        56: '',
        1: '0xaba8cac6866b83ae4eec97dd07ed254282f6ad8a',
      },
      decimals: 24,
      projectLink: 'http://yam.finance/',
    },
    crv: {
      symbol: 'CRV',
      address: {
        56: '',
        1: '0xd533a949740bb3306d119cc777fa900ba034cd52',
      },
      decimals: 18,
      projectLink: 'https://www.curve.fi/',
    },
    uni_eth: {
      symbol: 'UNI',
      address: {
        56: '',
        1: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
      },
      decimals: 18,
      projectLink: 'https://uniswap.org/',
    },
    xsushi: {
      symbol: 'xSUSHI',
      address: {
        56: '',
        1: '0x8798249c2e607446efb7ad49ec89dd1865ff4272',
      },
      decimals: 18,
      projectLink: '',
    },
    cdai: {
      symbol: 'cDAI',
      address: {
        56: '',
        1: '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
      },
      decimals: 8,
      projectLink: 'https://compound.finance/',
    },
    cream: {
      symbol: 'CREAM',
      address: {
        56: '',
        1: '0x2ba592f78db6436527729929aaf6c908497cb200',
      },
      decimals: 18,
      projectLink: 'https://cream.finance/',
    },
    meme: {
      symbol: 'MEME',
      address: {
        56: '',
        1: '0xd5525d397898e5502075ea5e830d8914f6f0affe',
      },
      decimals: 8,
      projectLink: 'https://dontbuymeme.com/',
    },
    wnxm: {
      symbol: 'wNXM',
      address: {
        56: '',
        1: '0x0d438f3b5175bebc262bf23753c1e53d03432bde',
      },
      decimals: 18,
      projectLink: 'https://nexusmutual.io/',
    },
    yearn: {
      symbol: 'YEARN',
      address: {
        56: '',
        1: '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c',
      },
      decimals: 18,
      projectLink: '',
    },
    cro: {
      symbol: 'CRO',
      address: {
        56: '',
        1: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
      },
      decimals: 8,
      projectLink: 'https://chain.crypto.com/',
    },
    renbtc: {
      symbol: 'renBTC',
      address: {
        56: '',
        1: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
      },
      decimals: 8,
      projectLink: 'https://renproject.io/',
    },
    hegic: {
      symbol: 'HEGIC',
      address: {
        56: '',
        1: '0x584bc13c7d411c00c01a62e8019472de68768430',
      },
      decimals: 18,
      projectLink: 'https://www.hegic.co/',
    },
    tbtc: {
      symbol: 'TBTC',
      address: {
        56: '',
        1: '0x8daebade922df735c38c80c7ebd708af50815faa',
      },
      decimals: 18,
      projectLink: 'https://tbtc.network/',
    },
    mkr: {
      symbol: 'MKR',
      address: {
        56: '',
        1: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
      },
      decimals: 18,
      projectLink: 'https://makerdao.com/',
    },
    core: {
      symbol: 'CORE',
      address: {
        56: '',
        1: '0x62359ed7505efc61ff1d56fef82158ccaffa23d7',
      },
      decimals: 18,
      projectLink: 'https://cvault.finance/',
    },
    pickle: {
      symbol: 'PICKLE',
      address: {
        56: '',
        1: '0x429881672b9ae42b8eba0e26cd9c73711b891ca5',
      },
      decimals: 18,
      projectLink: 'https://pickle.finance/',
    },
    omg: {
      symbol: 'OMG',
      address: {
        56: '',
        1: '0xd26114cd6ee289accf82350c8d8487fedb8a0c07',
      },
      decimals: 18,
      projectLink: 'https://omg.network/',
    },
    aave_eth: {
      symbol: 'AAVE',
      address: {
        56: '',
        1: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
      },
      decimals: 18,
      projectLink: 'https://aave.com/',
    },
    amp: {
      symbol: 'AMP',
      address: {
        56: '',
        1: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
      },
      decimals: 18,
      projectLink: 'https://amptoken.org/',
    },
    ghst: {
      symbol: 'GHST',
      address: {
        56: '',
        1: '0x3f382dbd960e3a9bbceae22651e88158d2791550',
      },
      decimals: 18,
      projectLink: 'https://www.aavegotchi.com/',
    },
    rari: {
      symbol: 'RARI',
      address: {
        56: '',
        1: '0xfca59cd816ab1ead66534d82bc21e7515ce441cf',
      },
      decimals: 18,
      projectLink: 'https://rarible.com/',
    },
    dpi: {
      symbol: 'DPI',
      address: {
        56: '',
        1: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b',
      },
      decimals: 18,
      projectLink: 'https://www.indexcoop.com/dpi',
    },
    vbtc: {
      symbol: 'VBTC',
      address: {
        56: '',
        1: '0xe1406825186d63980fd6e2ec61888f7b91c4bae4',
      },
      decimals: 18,
      projectLink: 'https://strudel.finance/',
    },
    yam: {
      symbol: 'YAM',
      address: {
        56: '',
        1: '0x0aacfbec6a24756c20d41914f2caba817c0d8521',
      },
      decimals: 18,
      projectLink: 'http://yam.finance/',
    },
    farm: {
      symbol: 'FARM',
      address: {
        56: '',
        1: '0xa0246c9032bc3a600820415ae600c6388619a14d',
      },
      decimals: 18,
      projectLink: 'https://harvest.finance/',
    },
    dough: {
      symbol: 'DOUGH',
      address: {
        56: '',
        1: '0xad32a8e6220741182940c5abf610bde99e737b2d',
      },
      decimals: 18,
      projectLink: 'https://pools.piedao.org/',
    },
    stake: {
      symbol: 'STAKE',
      address: {
        56: '',
        1: '0x0ae055097c6d159879521c384f1d2123d1f195e6',
      },
      decimals: 18,
      projectLink: 'https://xdaichain.com/',
    },
    rsr: {
      symbol: 'RSR',
      address: {
        56: '',
        1: '0x8762db106b2c2a0bccb3a80d1ed41273552616e8',
      },
      decimals: 18,
      projectLink: 'https://reserve.org/',
    },
    audio: {
      symbol: 'AUDIO',
      address: {
        56: '',
        1: '0x18aaa7115705e8be94bffebde57af9bfc265b998',
      },
      decimals: 18,
      projectLink: 'https://audius.co/',
    },
    hez: {
      symbol: 'HEZ',
      address: {
        56: '',
        1: '0xeef9f339514298c6a857efcfc1a762af84438dee',
      },
      decimals: 18,
      projectLink: 'https://hermez.io/',
    },
    akro: {
      symbol: 'AKRO',
      address: {
        56: '',
        1: '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7',
      },
      decimals: 18,
      projectLink: 'https://akropolis.io/',
    },
    yax: {
      symbol: 'YAX',
      address: {
        56: '',
        1: '0xb1dc9124c395c1e97773ab855d66e879f053a289',
      },
      decimals: 18,
      projectLink: 'https://yaxis.io/',
    },
    ousd: {
      symbol: 'OUSD',
      address: {
        56: '',
        1: '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86',
      },
      decimals: 18,
      projectLink: 'https://www.ousd.com/',
    },
    surf: {
      symbol: 'SURF',
      address: {
        56: '',
        1: '0xea319e87cf06203dae107dd8e5672175e3ee976c',
      },
      decimals: 18,
      projectLink: 'https://surf.finance/',
    },
    kp3r: {
      symbol: 'KP3R',
      address: {
        56: '',
        1: '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44',
      },
      decimals: 18,
      projectLink: 'https://keep3r.network/',
    },
    seen: {
      symbol: 'SEEN',
      address: {
        56: '',
        1: '0xca3fe04c7ee111f0bbb02c328c699226acf9fd33',
      },
      decimals: 18,
      projectLink: 'https://seen.haus/',
    },
    // axs_eth: {
    //   symbol: 'AXS',
    //   address: {
    //     56: '',
    //     1: '0xf5d669627376ebd411e34b98f19c868c8aba5ada',
    //   },
    //   decimals: 18,
    //   projectLink: 'https://axieinfinity.com/',
    // },
    esd: {
      symbol: 'ESD',
      address: {
        56: '',
        1: '0x36f3fd68e7325a35eb768f1aedaae9ea0689d723',
      },
      decimals: 18,
      projectLink: 'https://emptyset.finance/',
    },
    // ant: {
    //   symbol: 'ANT',
    //   address: {
    //     56: '',
    //     1: '0x960b236a07cf122663c4303350609a66a7b288c0',
    //   },
    //   decimals: 18,
    //   projectLink: 'https://aragon.org/',
    // },
    pnk: {
      symbol: 'PNK',
      address: {
        56: '',
        1: '0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d',
      },
      decimals: 18,
      projectLink: 'https://kleros.io/',
    },
    cvp: {
      symbol: 'CVP',
      address: {
        56: '',
        1: '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1',
      },
      decimals: 18,
      projectLink: 'https://powerpool.finance/',
    },
    yeti: {
      symbol: 'YETI',
      address: {
        56: '',
        1: '0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d',
      },
      decimals: 18,
      projectLink: 'https://powerindex.io/#/',
    },
    arch: {
      symbol: 'ARCH',
      address: {
        56: '',
        1: '0x1f3f9d3068568f8040775be2e8c03c103c61f3af',
      },
      decimals: 18,
      projectLink: 'https://archerdao.io/',
    },
    inj_eth: {
      symbol: 'INJ',
      address: {
        56: '',
        1: '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
      },
      decimals: 18,
      projectLink: 'https://injectiveprotocol.com/',
    },
    bor_eth: {
      symbol: 'BOR',
      address: {
        56: '',
        1: '0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9',
      },
      decimals: 18,
      projectLink: 'https://boringdao.com/',
    },
    sfi: {
      symbol: 'SFI',
      address: {
        56: '',
        1: '0xb753428af26e81097e7fd17f40c88aaa3e04902c',
      },
      decimals: 18,
      projectLink: 'http://saffron.finance/',
    },
    dfd_eth: {
      symbol: 'DFD',
      address: {
        56: '',
        1: '0x20c36f062a31865bed8a5b1e512d9a1a20aa333a',
      },
      decimals: 18,
      projectLink: 'https://dusd.finance/',
    },
    badger: {
      symbol: 'BADGER',
      address: {
        56: '',
        1: '0x3472a5a71965499acd81997a54bba8d852c6e53d',
      },
      decimals: 18,
      projectLink: 'https://badger.finance/',
    },
    zlot: {
      symbol: 'zLOT',
      address: {
        56: '',
        1: '0xa8e7ad77c60ee6f30bac54e2e7c0617bd7b5a03e',
      },
      decimals: 18,
      projectLink: 'https://zlot.finance/',
    },
    index: {
      symbol: 'INDEX',
      address: {
        56: '',
        1: '0x0954906da0bf32d5479e25f46056d22f08464cab',
      },
      decimals: 18,
      projectLink: 'https://www.indexcoop.com/',
    },
    swag: {
      symbol: 'SWAG',
      address: {
        56: '',
        1: '0x87edffde3e14c7a66c9b9724747a1c5696b742e6',
      },
      decimals: 18,
      projectLink: 'https://swag.finance/',
    },
    jrt: {
      symbol: 'JRT',
      address: {
        56: '',
        1: '0x8a9c67fee641579deba04928c4bc45f66e26343a',
      },
      decimals: 18,
      projectLink: 'https://jarvis.network/',
    },
    uwl: {
      symbol: 'UWL',
      address: {
        56: '',
        1: '0xdbdd6f355a37b94e6c7d32fef548e98a280b8df5',
      },
      decimals: 18,
      projectLink: 'https://www.uniwhales.io/',
    },
    ichi: {
      symbol: 'ICHI',
      address: {
        56: '',
        1: '0x903bef1736cddf2a537176cf3c64579c3867a881',
      },
      decimals: 9,
      projectLink: 'https://www.ichi.org/',
    },
    $rope: {
      symbol: '$ROPE',
      address: {
        56: '',
        1: '0x9d47894f8becb68b9cf3428d256311affe8b068b',
      },
      decimals: 18,
      projectLink: 'https://rope.lol/',
    },
    obtc: {
      symbol: 'oBTC',
      address: {
        56: '',
        1: '0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
      },
      decimals: 18,
      projectLink: 'https://boringdao.com/',
    },
    mbbased: {
      symbol: 'mbBASED',
      address: {
        56: '',
        1: '0x26cf82e4ae43d31ea51e72b663d26e26a75af729',
      },
      decimals: 18,
      projectLink: '',
    },
    dsd: {
      symbol: 'DSD',
      address: {
        56: '',
        1: '0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3',
      },
      decimals: 18,
      projectLink: 'https://dsd.finance/app/#/',
    },
    ntrump: {
      symbol: 'nTrump',
      address: {
        56: '',
        1: '0x44ea84a85616f8e9cd719fc843de31d852ad7240',
      },
      decimals: 15,
      projectLink: 'https://www.augur.net/',
    },
    ust_eth: {
      symbol: 'UST',
      address: {
        56: '',
        1: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
      },
      decimals: 18,
      projectLink: 'https://terra.money/',
    },
    fnx: {
      symbol: 'FNX',
      address: {
        56: '',
        1: '0xef9cd7882c067686691b6ff49e650b43afbbcc6b',
      },
      decimals: 18,
      projectLink: 'https://etherscan.io/token/FinNexus.io',
    },
    bcp: {
      symbol: 'BCP',
      address: {
        56: '',
        1: '0xe4f726adc8e89c6a6017f01eada77865db22da14',
      },
      decimals: 18,
      projectLink: 'https://www.piedao.org/',
    },
    ypie: {
      symbol: 'YPIE',
      address: {
        56: '',
        1: '0x17525e4f4af59fbc29551bc4ece6ab60ed49ce31',
      },
      decimals: 18,
      projectLink: 'https://www.piedao.org/',
    },
    defl: {
      symbol: 'DEFI',
      address: {
        56: '',
        1: '0x78f225869c08d478c34e5f645d07a87d3fe8eb78',
      },
      decimals: 18,
      projectLink: 'https://www.piedao.org/',
    },
    pixel: {
      symbol: 'PIXEL',
      address: {
        56: '',
        1: '0x1590ABe3612Be1CB3ab5B0f28874D521576e97Dc',
      },
      decimals: 18,
      projectLink: '',
    },
    vusd: {
      symbol: 'VUSD',
      address: {
        56: '',
        1: '0x677ddbd918637e5f2c79e164d402454de7da8619',
      },
      decimals: 18,
      projectLink: '',
    },
    xyz: {
      symbol: 'XYZ',
      address: {
        56: '',
        1: '0x618679dF9EfCd19694BB1daa8D00718Eacfa2883',
      },
      decimals: 18,
      projectLink: 'https://universe.xyz/',
    },
    ndx: {
      symbol: 'NDX',
      address: {
        56: '',
        1: '0x86772b1409b61c639EaAc9Ba0AcfBb6E238e5F83',
      },
      decimals: 18,
      projectLink: 'https://indexed.finance/',
    },
    degen: {
      symbol: 'DEGEN',
      address: {
        56: '',
        1: '0x126c121f99e1E211dF2e5f8De2d96Fa36647c855',
      },
      decimals: 18,
      projectLink: 'https://indexed.finance/',
    },
    one1: {
      symbol: '1ONE',
      address: {
        56: '',
        1: '0xD5cd84D6f044AbE314Ee7E414d37cae8773ef9D3',
      },
      decimals: 18,
      projectLink: '',
    },
    cqt: {
      symbol: 'CQT',
      address: {
        56: '',
        1: '0xd417144312dbf50465b1c641d016962017ef6240',
      },
      decimals: 18,
      projectLink: 'https://www.covalenthq.com/',
    },
    dvf: {
      symbol: 'DVF',
      address: {
        56: '',
        1: '0xdddddd4301a082e62e84e43f474f044423921918',
      },
      decimals: 18,
      projectLink: 'https://www.deversifi.com/',
    },
    ankr: {
      symbol: 'ANKR',
      address: {
        56: '',
        1: '0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4',
      },
      decimals: 18,
      projectLink: 'https://www.ankr.com/',
    },
    onix: {
      symbol: 'ONX',
      address: {
        56: '',
        1: '0xE0aD1806Fd3E7edF6FF52Fdb822432e847411033',
      },
      decimals: 18,
      projectLink: 'https://onx.finance',
    },
    ibeur: {
      symbol: 'ibEUR',
      address: {
        56: '',
        1: '0x96E61422b6A9bA0e068B6c5ADd4fFaBC6a4aae27',
      },
      decimals: 18,
      projectLink: 'https://yearn.fi/lend',
    },
    eden: {
      symbol: 'EDEN',
      address: {
        56: '',
        1: '0x1559FA1b8F28238FD5D76D9f434ad86FD20D1559',
      },
      decimals: 18,
      projectLink: '',
    },
    tower: {
      symbol: 'TOWER',
      address: {
        56: '',
        1: '0x1C9922314ED1415c95b9FD453c3818fd41867d0B',
      },
      decimals: 18,
      projectLink: 'https://crazydefenseheroes.com/',
    },
    fei: {
      symbol: 'FEI',
      address: {
        56: '',
        1: '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
      },
      decimals: 18,
      projectLink: 'https://fei.money/',
    },
    fold: {
      symbol: 'FOLD',
      address: {
        56: '',
        1: '0xd084944d3c05CD115C09d072B9F44bA3E0E45921',
      },
      decimals: 18,
      projectLink: 'https://www.manifoldfinance.com/',
    },
    bond: {
      symbol: 'BOND',
      address: {
        56: '',
        1: '0x0391D2021f89DC339F60Fff84546EA23E337750f',
      },
      decimals: 18,
      projectLink: 'https://barnbridge.com/',
    },
    sak3: {
      symbol: 'SAK3',
      address: {
        56: '',
        1: '0xe9F84dE264E91529aF07Fa2C746e934397810334',
      },
      decimals: 18,
      projectLink: 'https://coinmarketcap.com/currencies/sake/',
    },
    alusd: {
      symbol: 'alUSD',
      address: {
        56: '',
        1: '0xBC6DA0FE9aD5f3b0d58160288917AA56653660E9',
      },
      decimals: 18,
      projectLink: 'https://alchemix.fi/',
    },
    near_eth: {
      symbol: 'NEAR',
      address: {
        56: '',
        1: '0x85F17Cf997934a597031b2E18a9aB6ebD4B9f6a4',
      },
      decimals: 18,
      projectLink: '',
    },
    bpt: {
      symbol: 'BPT',
      address: {
        56: '',
        1: '0x0eC9F76202a7061eB9b3a7D6B59D36215A7e37da',
      },
      decimals: 18,
      projectLink: 'https://coinmarketcap.com/currencies/blackpool/',
    },
    ruler: {
      symbol: 'RULER',
      address: {
        56: '',
        1: '0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8',
      },
      decimals: 18,
      projectLink: 'https://rulerprotocol.com/',
    },
    mln: {
      symbol: 'MLN',
      address: {
        56: '',
        1: '0xec67005c4e498ec7f55e092bd1d35cbc47c91892',
      },
      decimals: 18,
      projectLink: 'https://enzyme.finance/',
    },
    lev: {
      symbol: 'LEV',
      address: {
        56: '',
        1: '0xbc194e6f748a222754C3E8b9946922c09E7d4e91',
      },
      decimals: 18,
      projectLink: 'https://lever.network/',
    },
    move: {
      symbol: 'MOVE',
      address: {
        56: '',
        1: '0x3FA729B4548beCBAd4EaB6EF18413470e6D5324C',
      },
      decimals: 18,
      projectLink: 'https://viamover.com/',
    },
    umb: {
      symbol: 'UMB',
      address: {
        56: '',
        1: '0x6fC13EACE26590B80cCCAB1ba5d51890577D83B2',
      },
      decimals: 18,
      projectLink: 'https://www.umb.network/',
    },
    naos: {
      symbol: 'NAOS',
      address: {
        56: '',
        1: '0x4a615bB7166210CCe20E6642a6f8Fb5d4D044496',
      },
      decimals: 18,
      projectLink: 'https://naos.finance/',
    },
    woofy: {
      symbol: 'WOOFY',
      address: {
        56: '',
        1: '0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
      },
      decimals: 18,
      projectLink: 'https://woofy.finance/',
    },
    ldn: {
      symbol: 'LDN',
      address: {
        56: '',
        1: '0xb29663Aa4E2e81e425294193616c1B102B70a158',
      },
      decimals: 18,
      projectLink: 'https://www.ludenaprotocol.io/',
    },
    blo: {
      symbol: 'BLO',
      address: {
        56: '',
        1: '0x68481f2c02BE3786987ac2bC3327171C5D05F9Bd',
      },
      decimals: 18,
      projectLink: '',
    },
    ilv: {
      symbol: 'ILV',
      address: {
        56: '',
        1: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
      },
      decimals: 18,
      projectLink: 'https://illuvium.io/',
    },
    $dg: {
      symbol: '$DG',
      address: {
        56: '',
        1: '0xee06a81a695750e71a662b51066f2c74cf4478a0',
      },
      decimals: 18,
      projectLink: 'https://decentral.games/',
    },
    drc: {
      symbol: 'DRC',
      address: {
        56: '',
        1: '0xb78b3320493a4efaa1028130c5ba26f0b6085ef8',
      },
      decimals: 18,
      projectLink: 'https://dracula.sucks/',
    },
    wasabi: {
      symbol: 'WASABI',
      address: {
        56: '',
        1: '0x896e145568624a498c5a909187363AE947631503',
      },
      decimals: 18,
      projectLink: 'https://wasabix.finance/',
    },
    mana: {
      symbol: 'MANA',
      address: {
        56: '',
        1: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
      },
      decimals: 18,
      projectLink: 'https://decentraland.org/',
    },
    pend: {
      symbol: 'PENDLE',
      address: {
        56: '',
        1: '0x808507121b80c02388fad14726482e061b8da827',
      },
      decimals: 18,
      projectLink: 'https://pendle.finance/',
    },
    mm: {
      symbol: 'MM',
      address: {
        56: '',
        1: '0xa283aa7cfbb27ef0cfbcb2493dd9f4330e0fd304',
      },
      decimals: 18,
      projectLink: 'https://mushrooms.finance/',
    },
    zig: {
      symbol: 'ZIG',
      address: {
        56: '',
        1: '0x7BeBd226154E865954A87650FAefA8F485d36081',
      },
      decimals: 18,
      projectLink: 'https://coinmarketcap.com/currencies/zigcoin/',
    },
    wbtc: {
      symbol: 'WBTC',
      address: {
        56: '',
        1: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
      },
      decimals: 8,
      projectLink: 'https://www.wbtc.network/',
    },
    ibbtc: {
      symbol: 'ibBTC',
      address: {
        56: '',
        1: '0xc4E15973E6fF2A35cC804c2CF9D2a1b817a8b40F',
      },
      decimals: 18,
      projectLink: '',
    },
    btc2xfli: {
      symbol: 'BTC2x-FLI',
      address: {
        56: '',
        1: '0x0b498ff89709d3838a063f1dfa463091f9801c2b',
      },
      decimals: 18,
      projectLink: 'https://www.tokensets.com/portfolio/btcfli',
    },
    bask: {
      symbol: 'BASK',
      address: {
        56: '',
        1: '0x44564d0bd94343f72E3C8a0D22308B7Fa71DB0Bb',
      },
      decimals: 18,
      projectLink: 'https://basketdao.org/',
    },
    bdi: {
      symbol: 'BDI',
      address: {
        56: '',
        1: '0x0309c98b1bffa350bcb3f9fb9780970ca32a5060',
      },
      decimals: 18,
      projectLink: 'https://basketdao.org/',
    },
    axs_eth: {
      symbol: 'AXS',
      address: {
        56: '',
        1: '0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b',
      },
      decimals: 18,
      projectLink: 'https://axieinfinity.com/',
    },
    yvboost: {
      symbol: 'yvBOOST',
      address: {
        56: '',
        1: '0x9d409a0A012CFbA9B15F6D4B36Ac57A46966Ab9a',
      },
      decimals: 18,
      projectLink: 'https://docs.yearn.finance/',
    },
    bzrx: {
      symbol: 'BZRX',
      address: {
        56: '',
        1: '0xa30911e072A0C88D55B5D0A0984B66b0D04569d0',
      },
      decimals: 18,
      projectLink: '',
    },
    yld: {
      symbol: 'YLD',
      address: {
        56: '',
        1: '0xF94b5C5651c888d928439aB6514B93944eEE6F48',
      },
      decimals: 18,
      projectLink: 'https://www.yield.app/',
    },
    pool: {
      symbol: 'POOL',
      address: {
        56: '',
        1: '0x0cEC1A9154Ff802e7934Fc916Ed7Ca50bDE6844e',
      },
      decimals: 18,
      projectLink: 'https://pooltogether.com/',
    },
    ohm: {
      symbol: 'OHM',
      address: {
        56: '',
        1: '0x383518188C0C6d7730D91b2c03a03C837814a899',
      },
      decimals: 18,
      projectLink: 'https://olympusdao.finance/#/',
    },
    mark: {
      symbol: 'MARK',
      address: {
        56: '',
        1: '0x67c597624b17b16fb77959217360b7cd18284253',
      },
      decimals: 9,
      projectLink: 'https://benchmarkprotocol.finance/',
    },
    must: {
      symbol: 'MUST',
      address: {
        56: '',
        1: '0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f',
      },
      decimals: 18,
      projectLink: 'https://www.cometh.io/',
    },
    egt: {
      symbol: 'EGT',
      address: {
        56: '',
        1: '0x2aA5cE395b00CC486159aDbDD97c55b535CF2cf9',
      },
      decimals: 18,
      projectLink: 'https://elasticdao.org/',
    },
    poly: {
      symbol: 'POLY',
      address: {
        56: '',
        1: '0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC',
      },
      decimals: 18,
      projectLink: 'https://www.polymath.network/',
    },
    armor: {
      symbol: 'ARMOR',
      address: {
        56: '',
        1: '0x1337DEF16F9B486fAEd0293eb623Dc8395dFE46a',
      },
      decimals: 18,
      projectLink: 'https://armor.fi/',
    },
    mask: {
      symbol: 'MASK',
      address: {
        56: '',
        1: '0x0fe629d1E84E171f8fF0C1Ded2Cc2221Caa48a3f',
      },
      decimals: 18,
      projectLink: 'https://nftx.org/#/',
    },
    dao: {
      symbol: 'DAO',
      address: {
        56: '',
        1: '0x0f51bb10119727a7e5eA3538074fb341F56B09Ad',
      },
      decimals: 18,
      projectLink: 'https://daomaker.com/',
    },
    grt: {
      symbol: 'GRT',
      address: {
        56: '',
        1: '0xc944E90C64B2c07662A292be6244BDf05Cda44a7',
      },
      decimals: 18,
      projectLink: 'https://thegraph.com/',
    },
    punk: {
      symbol: 'PUNK',
      address: {
        56: '',
        1: '0x9cea2eD9e47059260C97d697f82b8A14EfA61EA5',
      },
      decimals: 18,
      projectLink: 'https://www.larvalabs.com/cryptopunks',
    },
    aergo: {
      symbol: 'AERGO',
      address: {
        56: '',
        1: '0x91Af0fBB28ABA7E31403Cb457106Ce79397FD4E6',
      },
      decimals: 18,
      projectLink: 'https://www.aergo.io/',
    },
    dnt: {
      symbol: 'DNT',
      address: {
        56: '',
        1: '0x0abdace70d3790235af448c88547603b945604ea',
      },
      decimals: 18,
      projectLink: 'https://district0x.io/',
    },
    pont: {
      symbol: 'pONT',
      address: {
        56: '',
        1: '0xcb46C550539ac3DB72dc7aF7c89B11c306C727c2',
      },
      decimals: 9,
      projectLink: '',
    },
    pwing: {
      symbol: 'pWING',
      address: {
        56: '',
        1: '0xDb0f18081b505A7DE20B18ac41856BCB4Ba86A1a',
      },
      decimals: 9,
      projectLink: '',
    },
    woo: {
      symbol: 'WOO',
      address: {
        56: '',
        1: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
      },
      decimals: 18,
      projectLink: 'https://woo.network/',
    },
    ocean: {
      symbol: 'OCEAN',
      address: {
        56: '',
        1: '0x967da4048cd07ab37855c090aaf366e4ce1b9f48',
      },
      decimals: 18,
      projectLink: 'https://oceanprotocol.com/',
    },
    stfiro: {
      symbol: 'stFIRO',
      address: {
        56: '',
        1: '0x160b1e5aabfd70b2fc40af815014925d71ceed7e',
      },
      decimals: 18,
      projectLink: 'https://stakehound.com/',
    },
    steth: {
      symbol: 'stETH',
      address: {
        56: '',
        1: '0xdfe66b14d37c77f4e9b180ceb433d1b164f0281d',
      },
      decimals: 18,
      projectLink: 'https://stakehound.com/',
    },
    inv: {
      symbol: 'INV',
      address: {
        56: '',
        1: '0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68',
      },
      decimals: 18,
      projectLink: 'https://inverse.finance/',
    },
    dfx: {
      symbol: 'DFX',
      address: {
        56: '',
        1: '0x888888435FDe8e7d4c54cAb67f206e4199454c60',
      },
      decimals: 18,
      projectLink: 'https://dfx.finance/',
    },
    rook: {
      symbol: 'ROOK',
      address: {
        56: '',
        1: '0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a',
      },
      decimals: 18,
      projectLink: 'https://keeperdao.com/',
    },
    sx: {
      symbol: 'SX',
      address: {
        56: '',
        1: '0x99fE3B1391503A1bC1788051347A1324bff41452',
      },
      decimals: 18,
      projectLink: 'https://sx.technology/',
    },
    punkbasic: {
      symbol: 'PUNK-BASIC',
      address: {
        56: '',
        1: '0x69BbE2FA02b4D90A944fF328663667DC32786385',
      },
      decimals: 18,
      projectLink: '',
    },
    nftx: {
      symbol: 'NFTX',
      address: {
        56: '',
        1: '0x87d73E916D7057945c9BcD8cdd94e42A6F47f776',
      },
      decimals: 18,
      projectLink: 'https://nftx.org/#/',
    },
    yla: {
      symbol: 'YLA',
      address: {
        56: '',
        1: '0x9ba60ba98413a60db4c651d4afe5c937bbd8044b',
      },
      decimals: 18,
      projectLink: 'https://powerindex.io/#/mainnet/0x9ba60bA98413A60dB4C651D4afE5C937bbD8044B/supply',
    },
    bbadger_eth: {
      symbol: 'bBADGER',
      address: {
        56: '',
        1: '0x19d97d8fa813ee2f51ad4b4e04ea08baf4dffc28',
      },
      decimals: 18,
      projectLink: '',
    },
    bdigg_eth: {
      symbol: 'bDIGG',
      address: {
        56: '',
        1: '0x7e7e112a68d8d2e221e11047a72ffc1065c38e1a',
      },
      decimals: 18,
      projectLink: '',
    },
    rlc: {
      symbol: 'RLC',
      address: {
        56: '',
        1: '0x607F4C5BB672230e8672085532f7e901544a7375',
      },
      decimals: 9,
      projectLink: 'http://iex.ec/',
    },
    $trdl: {
      symbol: '$TRDL',
      address: {
        56: '',
        1: '0x297d33e17e61c2ddd812389c2105193f8348188a',
      },
      decimals: 18,
      projectLink: 'https://strudel.finance/',
    },
    onevbtc: {
      symbol: 'oneVBTC',
      address: {
        56: '',
        1: '0x7bd198b9107496fd5cc3d7655af52f43a8edbc4c',
      },
      decimals: 9,
      projectLink: 'https://www.ichi.farm/#/mint?vbtc',
    },
    dextf: {
      symbol: 'DEXTF',
      address: {
        56: '',
        1: '0x5F64Ab1544D28732F0A24F4713c2C8ec0dA089f0',
      },
      decimals: 18,
      projectLink: 'https://dextf.com/',
    },
    usdp: {
      symbol: 'USDP',
      address: {
        56: '',
        1: '0x1456688345527bE1f37E9e627DA0837D6f08C925',
      },
      decimals: 18,
      projectLink: 'https://unit.xyz/',
    },
    duck: {
      symbol: 'DUCK',
      address: {
        56: '',
        1: '0x92E187a03B6CD19CB6AF293ba17F2745Fd2357D5',
      },
      decimals: 18,
      projectLink: 'https://unit.xyz/',
    },
    wscrt: {
      symbol: 'WSCRT',
      address: {
        56: '',
        1: '0x2b89bf8ba858cd2fcee1fada378d5cd6936968be',
      },
      decimals: 6,
      projectLink: 'https://scrt.network',
    },
    perp: {
      symbol: 'PERP',
      address: {
        56: '',
        1: '0xbc396689893d065f41bc2c6ecbee5e0085233447',
      },
      decimals: 18,
      projectLink: 'https://perp.fi/',
    },
    lina_eth: {
      symbol: 'LINA',
      address: {
        56: '',
        1: '0x3e9bc21c9b189c09df3ef1b824798658d5011937',
      },
      decimals: 18,
      projectLink: 'https://linear.finance/',
    },
    premia: {
      symbol: 'PREMIA',
      address: {
        56: '',
        1: '0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70',
      },
      decimals: 18,
      projectLink: 'https://premia.finance/',
    },
    deri_eth: {
      symbol: 'DERI',
      address: {
        56: '',
        1: '0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9',
      },
      decimals: 18,
      projectLink: 'https://deri.finance/',
    },
    usdn: {
      symbol: 'USDN',
      address: {
        56: '',
        1: '0x674C6Ad92Fd080e4004b2312b45f796a192D27a0',
      },
      decimals: 18,
      projectLink: 'https://neutrino.at/',
    },
    zrx: {
      symbol: 'ZRX',
      address: {
        56: '',
        1: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
      },
      decimals: 18,
      projectLink: 'https://0x.org/',
    },
    xft: {
      symbol: 'XFT',
      address: {
        56: '',
        1: '0xABe580E7ee158dA464b51ee1a83Ac0289622e6be',
      },
      decimals: 18,
      projectLink: 'https://offshift.io/',
    },
    punkzombie: {
      symbol: 'PUNK-ZOMBIE',
      address: {
        56: '',
        1: '0xf18ade29a225faa555e475ee01f9eb66eb4a3a74',
      },
      decimals: 18,
      projectLink: '',
    },
    punkattr5: {
      symbol: 'PUNK-ATTR-5',
      address: {
        56: '',
        1: '0xab9c92a9337a1494c6d545e48187fa37144403c8',
      },
      decimals: 18,
      projectLink: '',
    },
    punkattr4: {
      symbol: 'PUNK-ATTR-4',
      address: {
        56: '',
        1: '0x49706a576bb823cde3180c930f9947d59e2ded4d',
      },
      decimals: 18,
      projectLink: '',
    },
    punkfemale: {
      symbol: 'PUNK-FEMALE',
      address: {
        56: '',
        1: '0x27ffed7e5926fb2795fc85aaab558243f280a8a2',
      },
      decimals: 18,
      projectLink: '',
    },
    any: {
      symbol: 'ANY',
      address: {
        56: '',
        1: '0xf99d58e463a2e07e5692127302c20a191861b4d6',
      },
      decimals: 18,
      projectLink: 'https://anyswap.exchange/',
    },
    ftm_eth: {
      symbol: 'ANY',
      address: {
        56: '',
        1: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
      },
      decimals: 18,
      projectLink: 'https://www.fantom.foundation/',
    },
    vsp: {
      symbol: 'VSP',
      address: {
        56: '',
        1: '0x1b40183efb4dd766f11bda7a7c3ad8982e998421',
      },
      decimals: 18,
      projectLink: 'https://vesper.finance/',
    },
    mbtc: {
      symbol: 'mBTC',
      address: {
        56: '',
        1: '0x945facb997494cc2570096c74b5f66a3507330a1',
      },
      decimals: 18,
      projectLink: 'https://mstable.org/',
    },
    torn: {
      symbol: 'TORN',
      address: {
        56: '',
        1: '0x77777feddddffc19ff86db637967013e6c6a116c',
      },
      decimals: 18,
      projectLink: 'https://tornado.cash/',
    },
    yvecrvdao: {
      symbol: 'yveCRV-DAO',
      address: {
        56: '',
        1: '0xc5bddf9843308380375a611c18b50fb9341f502a',
      },
      decimals: 18,
      projectLink: 'https://docs.yearn.finance/',
    },
    nct: {
      symbol: 'NCT',
      address: {
        56: '',
        1: '0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e',
      },
      decimals: 18,
      projectLink: 'https://www.thehashmasks.com/nct',
    },
    rendoge: {
      symbol: 'renDOGE',
      address: {
        56: '',
        1: '0x3832d2f059e55934220881f831be501d180671a7',
      },
      decimals: 8,
      projectLink: 'https://renproject.io/',
    },
    alpha_eth: {
      symbol: 'ALPHA',
      address: {
        56: '',
        1: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
      },
      decimals: 18,
      projectLink: 'https://alphafinance.io/',
    },
    ibethv2: {
      symbol: 'ibETHv2',
      address: {
        56: '',
        1: '0xeea3311250fe4c3268f8e684f7c87a82ff183ec1',
      },
      decimals: 8,
      projectLink: '',
    },
    assy: {
      symbol: 'ASSSY',
      address: {
        56: '',
        1: '0xfa2562da1bba7b954f26c74725df51fb62646313',
      },
      decimals: 18,
      projectLink: 'https://powerpool.finance/',
    },
    aethc: {
      symbol: 'aETHc',
      address: {
        56: '',
        1: '0xe95a203b1a91a908f9b9ce46459d101078c2c3cb',
      },
      decimals: 18,
      projectLink: 'https://stakefi.ankr.com/',
    },
    bac: {
      symbol: 'BAC',
      address: {
        56: '',
        1: '0x3449FC1Cd036255BA1EB19d65fF4BA2b8903A69a',
      },
      decimals: 18,
      projectLink: 'https://basis.cash/',
    },
    gno: {
      symbol: 'GNO',
      address: {
        56: '',
        1: '0x6810e776880c02933d47db1b9fc05908e5386b96',
      },
      decimals: 18,
      projectLink: 'https://gnosis.io/',
    },
    idle: {
      symbol: 'IDLE',
      address: {
        56: '',
        1: '0x875773784af8135ea0ef43b5a374aad105c5d39e',
      },
      decimals: 18,
      projectLink: 'https://idle.finance/',
    },
    rgt: {
      symbol: 'RGT',
      address: {
        56: '',
        1: '0xd291e7a03283640fdc51b121ac401383a46cc623',
      },
      decimals: 18,
      projectLink: 'http://rari.capital/',
    },
    bao: {
      symbol: 'BAO',
      address: {
        56: '',
        1: '0x374cb8c27130e2c9e04f44303f3c8351b9de61c1',
      },
      decimals: 18,
      projectLink: 'https://www.bao.finance/',
    },
    mta: {
      symbol: 'MTA',
      address: {
        56: '',
        1: '0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2',
      },
      decimals: 18,
      projectLink: 'https://mstable.org/',
    },
    spank: {
      symbol: 'SPANK',
      address: {
        56: '',
        1: '0x42d6622deCe394b54999Fbd73D108123806f6a18',
      },
      decimals: 18,
      projectLink: 'https://spankchain.com/',
    },
    bab: {
      symbol: 'BAB',
      address: {
        56: '',
        1: '0xc36824905dff2eaaee7ecc09fcc63abc0af5abc5',
      },
      decimals: 18,
      projectLink: 'https://basis.cash/',
    },
    tusd_eth: {
      symbol: 'TUSD',
      address: {
        56: '',
        1: '0x0000000000085d4780B73119b644AE5ecd22b376',
      },
      decimals: 18,
      projectLink: 'https://trueusd.com/',
    },
    sdt: {
      symbol: 'SDT',
      address: {
        56: '',
        1: '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f',
      },
      decimals: 18,
      projectLink: 'https://stakedao.org/',
    },
    ldo: {
      symbol: 'LDO',
      address: {
        56: '',
        1: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
      },
      decimals: 18,
      projectLink: 'https://stake.lido.fi/',
    },
    mars: {
      symbol: 'Mars',
      address: {
        56: '',
        1: '0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7',
      },
      decimals: 18,
      projectLink: 'https://mars.poolin.fi/#/',
    },
    hbtc: {
      symbol: 'HBTC',
      address: {
        56: '',
        1: '0x0316eb71485b0ab14103307bf65a021042c6d380',
      },
      decimals: 18,
      projectLink: 'https://www.hbtc.finance/',
    },
    combo: {
      symbol: 'COMBO',
      address: {
        56: '',
        1: '0xffffffff2ba8f66d4e51811c5190992176930278',
      },
      decimals: 18,
      projectLink: 'https://furucombo.app/',
    },
    api3: {
      symbol: 'API3',
      address: {
        56: '',
        1: '0x0b38210ea11411557c13457d4da7dc6ea731b88a',
      },
      decimals: 18,
      projectLink: 'https://api3.org/',
    },
    digg: {
      symbol: 'DIGG',
      address: {
        56: '',
        1: '0x798d1be841a82a273720ce31c822c61a67a601c3',
      },
      decimals: 18,
      projectLink: 'https://badger.finance/',
    },
    cover: {
      symbol: 'COVER',
      address: {
        56: '',
        1: '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713',
      },
      decimals: 18,
      projectLink: 'https://www.coverprotocol.com/',
    },
    ant: {
      symbol: 'ANT',
      address: {
        56: '',
        1: '0xa117000000f279d81a1d3cc75430faa017fa5a2e',
      },
      decimals: 18,
      projectLink: 'https://aragon.org/',
    },
    dusd: {
      symbol: 'DUSD',
      address: {
        56: '',
        1: '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
      },
      decimals: 18,
      projectLink: 'https://app.dusd.finance/',
    },
    creth2: {
      symbol: 'CRETH2',
      address: {
        56: '',
        1: '0xcbc1065255cbc3ab41a6868c22d1f1c573ab89fd',
      },
      decimals: 18,
      projectLink: 'https://app.cream.finance/eth2/',
    },
    alpa_eth: {
      symbol: 'ALPA',
      address: {
        56: '',
        1: '0x7ca4408137eb639570f8e647d9bd7b7e8717514a',
      },
      decimals: 18,
      projectLink: 'https://alpaca.city/',
    },
    tru: {
      symbol: 'TRU',
      address: {
        56: '',
        1: '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
      },
      decimals: 8,
      projectLink: 'https://truefi.io/',
    },
    uop: {
      symbol: 'UOP',
      address: {
        56: '',
        1: '0xe4ae84448db5cfe1daf1e6fb172b469c161cb85f',
      },
      decimals: 18,
      projectLink: 'https://utopiagenesis.com/',
    },
    front_eth: {
      symbol: 'FRONT',
      address: {
        56: '',
        1: '0xf8c3527cc04340b208c854e985240c02f7b7793f',
      },
      decimals: 18,
      projectLink: 'https://frontier.xyz',
    },
    base: {
      symbol: 'BASE',
      address: {
        56: '',
        1: '0x07150e919b4de5fd6a63de1f9384828396f25fdc',
      },
      decimals: 9,
      projectLink: 'https://www.baseprotocol.org/',
    },
    ddx: {
      symbol: 'DDX',
      address: {
        56: '',
        1: '0x3a880652f47bfaa771908c07dd8673a787daed3a',
      },
      decimals: 18,
      projectLink: 'https://derivadex.com/',
    },
    mph: {
      symbol: 'MPH',
      address: {
        56: '',
        1: '0x8888801af4d980682e47f1a9036e589479e835c5',
      },
      decimals: 18,
      projectLink: 'https://88mph.app/',
    },
    bb_cdai: {
      symbol: 'bb_cDAI',
      address: {
        56: '',
        1: '0x673f9488619821ab4f4155fdffe06f6139de518f',
      },
      decimals: 18,
      projectLink: '',
    },
    
  }
  
  export default tokens
  