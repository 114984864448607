import React from 'react'

export default function Footer() {
    return (
        <footer className='w-100 ' style={{marginTop:'70px'}}>
                {/* <div className='   rounded-3  bg-box'>
                <div className='row px-2'>
                    <div className='col-3  m-auto'>
                        <img src="images/Icon_Coffee.png" style={{ maxWidth: '100%' }} alt="" />
                    </div>
                    <div className='col-9 d-flex justify-content-start flex-row align-items-center'>


                        <div className=''>
                            <h2 className='fs-5'>Buy us some coffee</h2>
                            <h5>Because our special ability is that we can turn coffee into code, you can buy us some coffee by send us USDT on BSC network to address</h5>
                        </div>



                    </div>
                </div>
                <div className='row '>
                    <div className='col-lg-3 col-md-2'></div>
                    <div className='col-lg-9 col-md-10'>
                        <div className='d-flex flex-row  border-start p-2' style={{ columnGap: '5px' }}><h5 className='text-break'> 0x5802d51e2D4CD93a1986FcE1b96C2c951801f720 </h5><i className="far fa-clone cursor-point" style={{ color: 'white' }} onClick={() => { navigator.clipboard.writeText('0x5802d51e2D4CD93a1986FcE1b96C2c951801f720') }} /></div>
                    </div>

                </div>
            </div> */}
            <div className='container' style={{height:'70vh'}}>
                    <div className='d-flex flex-column  mt-3 text-center'>
                        <h2 className='fs-5'>Support farms</h2> 
                        <p className='mt-1' style={{fontSize:'0.7rem',color:'rgba(255, 253, 253, 0.651)'}}>list of farms we supports here</p>
                    </div>
                    <div className='row mt-3 mb-2 rounded-3 bg-box'style={{height:'30vh'}}>

                        <div className='col-2'>
                        </div>

                        <div className='col-8 text-center mt-2' >
                            <a href="https://pancakeswap.finance/farms" target="_blank"> <img  className='size-farmsup' src="images/farms support/Pancake-01.svg" alt="" /> </a>
                            <a href="https://apeswap.finance/farms" target="_blank"> <img  className='size-farmsup' src="images/farms support/ApeSwap-01.svg" alt="" /> </a>
                            <a href="https://app.sushi.com/farm" target="_blank"> <img  className='size-farmsup' src="images/farms support/Sushi-01.svg" alt="" /> </a>
                        </div>

                        <div className='col-2'>
                        </div>

                    </div>


                </div>

            <div className='col-12 justify-content-center d-flex flex-column ' style={{padding:'35px 0 ',backgroundColor:'#190F56',height:'90px',alignItems:'center'}}><h2>Copyright © 2021 VSNT assets board, All rights Reserved</h2> </div>
           
        </footer>
    )
}
