import React from 'react'
import { Menu as UikitMenu } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import config from './config'
import useAuth from 'hooks/useAuth'
import Header from 'components/header'
import Footer from 'components/footer'

const Menu = (props) => {

  return (
<div className='bg'>
    <nav className="navbar navbar-light " style={{height:'80px',backgroundColor: '#190F56'}}>
  <div className='d-flex flex-row '>
<div className='p-2 fs-4 d-flex flex-row'>
  <img src="images/Icon_logoVSNT.png" style={{maxWidth:'50px'}} alt="" />
  <h1 className='p-2'>VSNT</h1> </div>
  <a href='https://www.vassanatoken.com/' className=' rounded mx-5 p-2 d-flex flex-row' style={{backgroundColor: '#FF5500',height:'40px',marginTop:'10px'}}><i className="fas fa-home mt-1" style={{color:'white'}}></i><h2 className='mt-1'>Home</h2></a>
  </div>
</nav>
<Header></Header>

   <div {...props}/>
   <Footer></Footer>
   </div>
  )
}
export default Menu
