import React, { useEffect, useState } from 'react'
import useRefresh from 'hooks/useRefresh'
import axios from 'axios'
import { async } from 'q'
export default function Header() {
    const [price, setPrice] = useState([{ symbol: '', lastPrice: '', priceChangePercent: '' }])
    const [check, setCheck] = useState(true)
    const [selectPrice, setSelectprice] = useState(['BTCUSDT', 'ETHUSDT', 'BNBUSDT','DOGEUSDT','XRPUSDT','USDCUSDT'])
    const { fastRefresh } = useRefresh();
    useEffect(() => {


        const listPrice = []
        const setStyle = () => {
            selectPrice.map((res, index) => {
                let elPrice = document.getElementById(index + '-price')
                let elArrow = document.getElementById(index + '-arrow')
                let elPricepercent = document.getElementById(index + '-pricePercent')
                let pricePercent = '0'
                elPrice.style.color = 'white'
                try {
                    pricePercent = price[index].priceChangePercent ? price[index].priceChangePercent : '0';

                } catch (error) {
                    console.log(error);
                }

                if (parseFloat(pricePercent) < 0) {
                    elArrow.classList.remove('fa-arrow-up')
                    elArrow.classList.add('fa-arrow-down')
                    elPricepercent.style.color = 'red'
                } else if (parseFloat(pricePercent) > 0) {
                    elArrow.classList.remove('fa-arrow-down')
                    elArrow.classList.add('fa-arrow-up')
                    elPricepercent.style.color = '#37ff00'
                } else {
                    elPricepercent.style.color = 'white'
                }
            })
        }
        const getPrice = async () => {
            await axios.get('https://api.binance.com/api/v3/ticker/24hr').then(async (res) => {
                await res.data.map((res, index) => {
                    if (selectPrice.includes(res.symbol)) {
                        listPrice.push(res);
                    }
                })
                setPrice(listPrice)
            }
            )
            setStyle()
        }
        getPrice()
    }, [fastRefresh])
    return (
        <div className='p-2 mt-3 container ' style={{ backgroundColor: '#605A89' }}>
            <div id="outer" className='' style={{ margin: '0 auto' }}>
                <div className='col-12'>
                    <div id="loop" className=''>
                        <div id="content" className=' d-flex flex-row justify-content-end' style={{ color: 'white' }}>
                            {price.map((res, index) => {
                                let result = ''
                                if (res.lastPrice != '') {
                                    result = parseFloat(res.lastPrice).toLocaleString();
                                }
                                return <h2 className='mx-5 d-flex flex-row align-items-center p-2 border-start'>
                                    <img style={{ maxWidth: '155px', margin: '0px 3px 0px 8px' }}
                                        src={`images/${res.symbol}.png`} alt="" />
                                    <div>

                                        <span id={index + '-price'} className="down-price">{" $" + result + " "}</span>
                                        <span id={index + '-pricePercent'} className="down-pricePercent"><i id={index + '-arrow'} className="fas mx-2" /> {res.priceChangePercent + ' %'}</span>
                                    </div>
                                </h2>
                            }
                            )} &nbsp;

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

