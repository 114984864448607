import tokens from './tokens'
import { FarmConfig } from './types'

const farms_sushiswap: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'ETH-USDT LP',
    lpAddresses: {
      56: '',
      1: '0x06da0fd433C1A5d7a4faa01111c044910A184553',
    },
    token: tokens.weth,
    quoteToken: tokens.usdt_eth,
  },
  {
    pid: 1,
    lpSymbol: 'USDC-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x397FF1542f962076d0BFE58eA045FfA2d347ACa0',
    },
    token: tokens.usdc_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 2,
    lpSymbol: 'DAI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xC3D03e4F041Fd4cD388c549Ee2A29a9E5075882f',
    },
    token: tokens.dai_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 3,
    lpSymbol: 'sUSD-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xF1F85b2C54a2bD284B1cf4141D64fD171Bd85539',
    },
    token: tokens.susd,
    quoteToken: tokens.weth,
  },
  {
    pid: 4,
    lpSymbol: 'COMP-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x31503dcb60119A812feE820bb7042752019F2355',
    },
    token: tokens.comp_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 5,
    lpSymbol: 'LEND-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x5E63360E891BD60C69445970256C260b0A6A54c6',
    },
    token: tokens.lend,
    quoteToken: tokens.weth,
  },
  {
    pid: 6,
    lpSymbol: 'SNX-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xA1d7b2d891e3A1f9ef4bBC5be20630C2FEB1c470',
    },
    token: tokens.snx_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 7,
    lpSymbol: 'UMA-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x001b6450083E531A5a7Bf310BD2c1Af4247E23D4',
    },
    token: tokens.uma,
    quoteToken: tokens.weth,
  },
  {
    pid: 8,
    lpSymbol: 'LINK-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xC40D16476380e4037e6b1A2594cAF6a6cc8Da967',
    },
    token: tokens.link_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 9,
    lpSymbol: 'BAND-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xA75F7c2F025f470355515482BdE9EFA8153536A8',
    },
    token: tokens.band_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 10,
    lpSymbol: 'ETH-AMPL LP',
    lpAddresses: {
      56: '',
      1: '0xCb2286d9471cc185281c4f763d34A962ED212962',
    },
    token: tokens.weth,
    quoteToken: tokens.ampl_eth,
  },
  {
    pid: 11,
    lpSymbol: 'YFI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x088ee5007C98a9677165D78dD2109AE4a3D04d0C',
    },
    token: tokens.yfi_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 12,
    lpSymbol: 'SUSHI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x795065dCc9f64b5614C407a6EFDC400DA6221FB0',
    },
    token: tokens.sushi_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 13,
    lpSymbol: 'REN-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x611CDe65deA90918c0078ac0400A72B0D25B9bb1',
    },
    token: tokens.ren,
    quoteToken: tokens.weth,
  },
  {
    pid: 14,
    lpSymbol: 'sUSD-$BASED LP',
    lpAddresses: {
      56: '',
      1: '0xaAD22f5543FCDaA694B68f94Be177B561836AE57',
    },
    token: tokens.susd,
    quoteToken: tokens.$based,
  },
  {
    pid: 15,
    lpSymbol: 'SRM-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x117d4288B3635021a3D612FE05a3Cbf5C717fEf2',
    },
    token: tokens.srm,
    quoteToken: tokens.weth,
  },
  {
    pid: 16,
    lpSymbol: 'YAMv2-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x95b54C8Da12BB23F7A5F6E26C38D04aCC6F81820',
    },
    token: tokens.yamv2,
    quoteToken: tokens.weth,
  },
  {
    pid: 17,
    lpSymbol: 'ETH-CRV LP',
    lpAddresses: {
      56: '',
      1: '0x58Dc5a51fE44589BEb22E8CE67720B5BC5378009',
    },
    token: tokens.weth,
    quoteToken: tokens.crv,
  },
  {
    pid: 18,
    lpSymbol: 'UNI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xDafd66636E2561b0284EDdE37e42d192F2844D40',
    },
    token: tokens.uni_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 19,
    lpSymbol: 'xSUSHI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x36e2FCCCc59e5747Ff63a03ea2e5C0c2C14911e7',
    },
    token: tokens.xsushi,
    quoteToken: tokens.weth,
  },
  {
    pid: 20,
    lpSymbol: 'cDAI-DAI LP',
    lpAddresses: {
      56: '',
      1: '0x0Cfe7968e7c34A51217a7C9b9dc1690F416E027e',
    },
    token: tokens.cdai,
    quoteToken: tokens.dai_eth,
  },
  {
    pid: 21,
    lpSymbol: 'WBTC-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xCEfF51756c56CeFFCA006cD410B03FFC46dd3a58',
    },
    token: tokens.wbtc,
    quoteToken: tokens.weth,
  },
  {
    pid: 22,
    lpSymbol: 'CREAM-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xf169CeA51EB51774cF107c88309717ddA20be167',
    },
    token: tokens.cream,
    quoteToken: tokens.weth,
  },
  {
    pid: 23,
    lpSymbol: 'ETH-MEME LP',
    lpAddresses: {
      56: '',
      1: '0x17b3C19Bd640a59E832AB73eCcF716CB47419846',
    },
    token: tokens.weth,
    quoteToken: tokens.meme,
  },
  {
    pid: 24,
    lpSymbol: 'wNXM-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xFcff3b04C499A57778ae2CF05584ab24278A7FCb',
    },
    token: tokens.wnxm,
    quoteToken: tokens.weth,
  },
  {
    pid: 25,
    lpSymbol: 'YEARN-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x382c4a5147Fd4090F7BE3A9Ff398F95638F5D39E',
    },
    token: tokens.yearn,
    quoteToken: tokens.weth,
  },
  {
    pid: 26,
    lpSymbol: 'WBTC-YEARN LP',
    lpAddresses: {
      56: '',
      1: '0x2024324a99231509a3715172d4F4f4E751b38d4d',
    },
    token: tokens.wbtc,
    quoteToken: tokens.yearn,
  },
  {
    pid: 27,
    lpSymbol: 'CRO-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x0be88ac4b5C81700acF3a606a52a31C261a24A35',
    },
    token: tokens.cro,
    quoteToken: tokens.weth,
  },
  {
    pid: 28,
    lpSymbol: 'WBTC-renBTC LP',
    lpAddresses: {
      56: '',
      1: '0x518d6CE2D7A689A591Bf46433443C31615b206C5',
    },
    token: tokens.wbtc,
    quoteToken: tokens.renbtc,
  },
  // {
  //   pid: 29,
  //   lpSymbol: 'DUMMY',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xE954c2d9Ff2a4D260Dcd32386B1F9Fc8135D2522',
  //   },
  //   token: tokens.,
  //   quoteToken: tokens.,
  // },
  // {
  //   pid: 30,
  //   lpSymbol: '',
  //   lpAddresses: {
  //     56: '',
  //     1: '',
  //   },
  //   token: tokens.,
  //   quoteToken: tokens.,
  // },
  {
    pid: 31,
    lpSymbol: 'HEGIC-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x6463Bd6026A2E7bFab5851b62969A92f7cca0eB6',
    },
    token: tokens.hegic,
    quoteToken: tokens.weth,
  },
  {
    pid: 32,
    lpSymbol: 'WBTC-TBTC LP',
    lpAddresses: {
      56: '',
      1: '0x2Dbc7dD86C6cd87b525BD54Ea73EBeeBbc307F68',
    },
    token: tokens.wbtc,
    quoteToken: tokens.tbtc,
  },
  {
    pid: 33,
    lpSymbol: 'MKR-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xBa13afEcda9beB75De5c56BbAF696b880a5A50dD',
    },
    token: tokens.mkr,
    quoteToken: tokens.weth,
  },
  {
    pid: 34,
    lpSymbol: 'CORE-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x68C6d02D44E16F1c20088731Ab032f849100D70f',
    },
    token: tokens.core,
    quoteToken: tokens.weth,
  },
  {
    pid: 35,
    lpSymbol: 'PICKLE-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x269Db91Fc3c7fCC275C2E6f22e5552504512811c',
    },
    token: tokens.pickle,
    quoteToken: tokens.weth,
  },
  {
    pid: 36,
    lpSymbol: 'ETH-OMG LP',
    lpAddresses: {
      56: '',
      1: '0x742c15d71eA7444964BC39b0eD729B3729ADc361',
    },
    token: tokens.weth,
    quoteToken: tokens.omg,
  },
  {
    pid: 37,
    lpSymbol: 'AAVE-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xD75EA151a61d06868E31F8988D28DFE5E9df57B4',
    },
    token: tokens.aave_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 38,
    lpSymbol: 'ETH-AMP LP',
    lpAddresses: {
      56: '',
      1: '0x15e86E6f65EF7EA1dbb72A5E51a07926fB1c82E3',
    },
    token: tokens.weth,
    quoteToken: tokens.amp,
  },
  {
    pid: 39,
    lpSymbol: 'xSUSHI-USDC LP',
    lpAddresses: {
      56: '',
      1: '0xd597924b16Cc1904d808285bC9044fd51CEEEaD7',
    },
    token: tokens.xsushi,
    quoteToken: tokens.usdc_eth,
  },
  {
    pid: 40,
    lpSymbol: 'GHST-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x5a2943B25ce0678Dc0b351928D2DB331A55D94eA',
    },
    token: tokens.ghst,
    quoteToken: tokens.weth,
  },
  {
    pid: 41,
    lpSymbol: 'ETH-RARI LP',
    lpAddresses: {
      56: '',
      1: '0x53aaBCcAE8C1713a6a150D9981D2ee867D0720e8',
    },
    token: tokens.weth,
    quoteToken: tokens.rari,
  },
  {
    pid: 42,
    lpSymbol: 'DPI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x34b13F8CD184F55d0Bd4Dd1fe6C07D46f245c7eD',
    },
    token: tokens.dpi,
    quoteToken: tokens.weth,
  },
  {
    pid: 43,
    lpSymbol: 'TBTC-VBTC',
    lpAddresses: {
      56: '',
      1: '0xbcEdc25CbB0EA44E03E41dC2d00D54Fe6d4646Db',
    },
    token: tokens.tbtc,
    quoteToken: tokens.vbtc,
  },
  {
    pid: 44,
    lpSymbol: 'YAM-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x0F82E57804D0B1F6FAb2370A43dcFAd3c7cB239c',
    },
    token: tokens.yam,
    quoteToken: tokens.weth,
  },
  // {
  //   pid: 45,
  //   lpSymbol: 'REDUCE',
  //   lpAddresses: {
  //     56: '',
  //     1: '',
  //   },
  //   token: tokens.,
  //   quoteToken: tokens.,
  // },
  {
    pid: 46,
    lpSymbol: 'FARM-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x69b39B89f9274a16e8A19B78E5eB47a4d91dAc9E',
    },
    token: tokens.farm,
    quoteToken: tokens.weth,
  },
  {
    pid: 47,
    lpSymbol: 'ETH-renBTC LP',
    lpAddresses: {
      56: '',
      1: '0x0289B9CD5859476Ce325aCa04309D36adDCEbDAA',
    },
    token: tokens.weth,
    quoteToken: tokens.renbtc,
  },
  {
    pid: 48,
    lpSymbol: 'DOUGH-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x97f34c8E5992EB985c5F740e7EE8c7e48a1de76a',
    },
    token: tokens.dough,
    quoteToken: tokens.weth,
  },
  {
    pid: 49,
    lpSymbol: 'STAKE-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x9Fc5b87b74B9BD239879491056752EB90188106D',
    },
    token: tokens.stake,
    quoteToken: tokens.weth,
  },
  {
    pid: 50,
    lpSymbol: 'RSR-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x6f58A1Aa0248A9F794d13Dc78E74Fc75140956D7',
    },
    token: tokens.rsr,
    quoteToken: tokens.weth,
  },
  {
    pid: 51,
    lpSymbol: 'TBTC-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xEe6d78755e06C31AE7A5EA2b29b35C073dfc00A9',
    },
    token: tokens.tbtc,
    quoteToken: tokens.weth,
  },
  {
    pid: 52,
    lpSymbol: 'AUDIO-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x4F871F310AD0E8a170db0021c0ce066859d37469',
    },
    token: tokens.audio,
    quoteToken: tokens.weth,
  },
  {
    pid: 53,
    lpSymbol: 'AKRO-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x364248b2f1f57C5402d244b2D469A35B4C0e9dAB',
    },
    token: tokens.akro,
    quoteToken: tokens.weth,
  },
  {
    pid: 54,
    lpSymbol: 'ETH-HEZ LP',
    lpAddresses: {
      56: '',
      1: '0xD7c2A4aa31E1bF08dc7Ff44C9980fa8573E10C1B',
    },
    token: tokens.weth,
    quoteToken: tokens.hez,
  },
  {
    pid: 55,
    lpSymbol: 'YAX-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x033ecD066376aFec5E6383BC9F1F15bE4C62dc89',
    },
    token: tokens.yax,
    quoteToken: tokens.weth,
  },
  {
    pid: 56,
    lpSymbol: 'OUSD-USDT LP',
    lpAddresses: {
      56: '',
      1: '0xe4455FdEc181561e9Ffe909Dde46AAEaeDC55283',
    },
    token: tokens.ousd,
    quoteToken: tokens.usdt_eth,
  },
  {
    pid: 57,
    lpSymbol: 'ETH-SURF LP',
    lpAddresses: {
      56: '',
      1: '0x0bff31d8179Da718A7ee3669853cF9978c90a24a',
    },
    token: tokens.weth,
    quoteToken: tokens.surf,
  },
  {
    pid: 58,
    lpSymbol: 'KP3R-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xaf988afF99d3d0cb870812C325C588D8D8CB7De8',
    },
    token: tokens.kp3r,
    quoteToken: tokens.weth,
  },
  {
    pid: 59,
    lpSymbol: 'ETH-SEEN LP',
    lpAddresses: {
      56: '',
      1: '0xC5Fa164247d2F8D68804139457146eFBde8370F6',
    },
    token: tokens.weth,
    quoteToken: tokens.seen,
  },
  {
    pid: 60,
    lpSymbol: 'ETH-AXS LP',
    lpAddresses: {
      56: '',
      1: '0x35a0d9579B1E886702375364Fe9c540f97E4517B',
    },
    token: tokens.weth,
    quoteToken: tokens.axs_eth,
  },
  // {
  //   pid: 61,
  //   lpSymbol: 'COVERCHEF',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x5E94cb9C309775763EDbD4abf248a229880e68c6',
  //   },
  //   token: tokens.,
  //   quoteToken: tokens.,
  // },
  // {
  //   pid: 62,
  //   lpSymbol: 'AMPLIQTEST',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xdc549b8199Ec396Fd9fF7E431cfC3CF9B40f2163',
  //   },
  //   token: tokens.,
  //   quoteToken: tokens.,
  // },
  {
    pid: 63,
    lpSymbol: 'ESD-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xDFf71165a646BE71fCfbaa6206342FAa503AeD5D',
    },
    token: tokens.esd,
    quoteToken: tokens.weth,
  },
  {
    pid: 64,
    lpSymbol: 'ANT-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x378b4c5f2a8a0796A8d4c798Ef737cF00Ae8e667',
    },
    token: tokens.ant,
    quoteToken: tokens.weth,
  },
  {
    pid: 65,
    lpSymbol: 'PNK-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xEF4F1D5007B4FF88c1A56261fec00264AF6001Fb',
    },
    token: tokens.pnk,
    quoteToken: tokens.weth,
  },
  {
    pid: 66,
    lpSymbol: 'CVP-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x1C580CC549d03171B13b55074Dc1658F60641C73',
    },
    token: tokens.cvp,
    quoteToken: tokens.weth,
  },
  {
    pid: 67,
    lpSymbol: 'YETI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xf45D97F9D457661783146D63DD13DA20ce9bf847',
    },
    token: tokens.yeti,
    quoteToken: tokens.weth,
  },
  {
    pid: 68,
    lpSymbol: 'ARCH-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x4441eb3076f828D5176f4Fe74d7c775542daE106',
    },
    token: tokens.arch,
    quoteToken: tokens.weth,
  },
  {
    pid: 69,
    lpSymbol: 'ETH-INJ LP',
    lpAddresses: {
      56: '',
      1: '0xFb3cD0B8A5371fe93ef92E3988D30Df7931E2820',
    },
    token: tokens.weth,
    quoteToken: tokens.inj_eth,
  },
  {
    pid: 70,
    lpSymbol: 'BOR-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x44D34985826578e5ba24ec78c93bE968549BB918',
    },
    token: tokens.bor_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 71,
    lpSymbol: 'SFI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x23a9292830Fc80dB7f563eDb28D2fe6fB47f8624',
    },
    token: tokens.sfi,
    quoteToken: tokens.weth,
  },
  {
    pid: 72,
    lpSymbol: 'DFD-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xb12aa722a3A4566645F079B6F10c89A3205b6c2c',
    },
    token: tokens.dfd_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 73,
    lpSymbol: 'WBTC-BADGER LP',
    lpAddresses: {
      56: '',
      1: '0x110492b31c59716AC47337E616804E3E3AdC0b4a',
    },
    token: tokens.wbtc,
    quoteToken: tokens.badger,
  },
  {
    pid: 74,
    lpSymbol: 'zLOT-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x9360b76f8f5F932AC33D46A3CE82ad6C52A713E5',
    },
    token: tokens.zlot,
    quoteToken: tokens.weth,
  },
  {
    pid: 75,
    lpSymbol: 'INDEX-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xA73DF646512C82550C2b3C0324c4EEdEE53b400C',
    },
    token: tokens.index,
    quoteToken: tokens.weth,
  },
  {
    pid: 76,
    lpSymbol: 'SWAG-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xadeAa96A81eBBa4e3A5525A008Ee107385d588C3',
    },
    token: tokens.swag,
    quoteToken: tokens.weth,
  },
  {
    pid: 77,
    lpSymbol: 'JRT-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xF1360C4ae1cead17B588ec1111983d2791B760d3',
    },
    token: tokens.jrt,
    quoteToken: tokens.weth,
  },
  {
    pid: 78,
    lpSymbol: 'ETH-UWL LP',
    lpAddresses: {
      56: '',
      1: '0x0040a2CEBc65894BC2cFb57565f9ACfa33Fab137',
    },
    token: tokens.weth,
    quoteToken: tokens.uwl,
  },
  {
    pid: 79,
    lpSymbol: 'ICHI-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x9cD028B1287803250B1e226F0180EB725428d069',
    },
    token: tokens.ichi,
    quoteToken: tokens.weth,
  },
  {
    pid: 80,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x67e475577B4036EE4f0F12fa2d538Ed18CEF48e3',
    },
    token: tokens.$rope,
    quoteToken: tokens.weth,
  },
  {
    pid: 81,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x53E9fB796b2feb4B3184AFDf601C2A2797548d88',
    },
    token: tokens.obtc,
    quoteToken: tokens.weth,
  },
  {
    pid: 82,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xE5f06db4F3473E7E35490F1F98017728496fe81E',
    },
    token: tokens.mbbased,
    quoteToken: tokens.weth,
  },
  {
    pid: 83,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x26d8151e631608570F3c28bec769C3AfEE0d73a3',
    },
    token: tokens.usdc_eth,
    quoteToken: tokens.dsd,
  },
  {
    pid: 84,
    lpSymbol: 'nTrump-DAI LP',
    lpAddresses: {
      56: '',
      1: '0xaB3F8E0214D862Bf7965d3CeC7431d7C1A85cb34',
    },
    token: tokens.ntrump,
    quoteToken: tokens.dai_eth,
  },
  {
    pid: 85,
    lpSymbol: 'UST-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x8B00eE8606CC70c2dce68dea0CEfe632CCA0fB7b',
    },
    token: tokens.ust_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 86,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xaa500101C73065f755Ba9b902d643705EF2523E3',
    },
    token: tokens.weth,
    quoteToken: tokens.fnx,
  },
  {
    pid: 87,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xeB1B57D4f7d4557B032B66c422bc94a8E4Af859e',
    },
    token: tokens.weth,
    quoteToken: tokens.bcp,
  },
  {
    pid: 88,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x5F30aAc9A472F6c33D5284f9D340C0d57eF33697',
    },
    token: tokens.ypie,
    quoteToken: tokens.weth,
  },
  {
    pid: 89,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x83E5e791F4aB29d1B0941Bc4D00f3D6027d1dae5',
    },
    token: tokens.defl,
    quoteToken: tokens.weth,
  },
  {
    pid: 90,
    lpSymbol: 'ALPHA-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xD8B8B575c943f3d63638c9563B464D204ED8B710',
    },
    token: tokens.base,
    quoteToken: tokens.weth,
  },
  {
    pid: 91,
    lpSymbol: 'DDX-USDC LP',
    lpAddresses: {
      56: '',
      1: '0xc2B0F2A7F736d3b908BdDE8608177c8Fc28C1690',
    },
    token: tokens.ddx,
    quoteToken: tokens.usdc_eth,
  },
  {
    pid: 92,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xB2C29e311916a346304f83AA44527092D5bd4f0F',
    },
    token: tokens.mph,
    quoteToken: tokens.weth,
  },
  {
    pid: 93,
    lpSymbol: 'USDT-FRONT LP',
    lpAddresses: {
      56: '',
      1: '0x98c2f9D752e044DC2e1F1743bF0b76A7096eCeb2',
    },
    token: tokens.usdt_eth,
    quoteToken: tokens.front_eth,
  },
  {
    pid: 94,
    lpSymbol: 'ALPHA-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x8C2e6A4af15C94cF4a86Cd3C067159F08571d780',
    },
    token: tokens.weth,
    quoteToken: tokens.uop,
  },
  {
    pid: 95,
    lpSymbol: 'TRU-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xfCEAAf9792139BF714a694f868A215493461446D',
    },
    token: tokens.tru,
    quoteToken: tokens.weth,
  },
  {
    pid: 96,
    lpSymbol: 'ALPHA-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xf55C33D94150d93c2cfb833bcCA30bE388b14964',
    },
    token: tokens.alpha_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 91,
    lpSymbol: 'DDX-USDC LP',
    lpAddresses: {
      56: '',
      1: '0xc2B0F2A7F736d3b908BdDE8608177c8Fc28C1690',
    },
    token: tokens.ddx,
    quoteToken: tokens.usdc_eth,
  },
  {
    pid: 92,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xB2C29e311916a346304f83AA44527092D5bd4f0F',
    },
    token: tokens.mph,
    quoteToken: tokens.weth,
  },
  {
    pid: 93,
    lpSymbol: 'USDT-FRONT LP',
    lpAddresses: {
      56: '',
      1: '0x98c2f9D752e044DC2e1F1743bF0b76A7096eCeb2',
    },
    token: tokens.usdt_eth,
    quoteToken: tokens.front_eth,
  },
  {
    pid: 94,
    lpSymbol: 'ALPHA-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x8C2e6A4af15C94cF4a86Cd3C067159F08571d780',
    },
    token: tokens.weth,
    quoteToken: tokens.uop,
  },
  {
    pid: 95,
    lpSymbol: 'TRU-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xfCEAAf9792139BF714a694f868A215493461446D',
    },
    token: tokens.tru,
    quoteToken: tokens.weth,
  },
  {
    pid: 96,
    lpSymbol: 'ALPHA-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xf55C33D94150d93c2cfb833bcCA30bE388b14964',
    },
    token: tokens.alpha_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 97,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xcA658217CE94dFB2156a49a8fAd0Ff752CaC39C2',
    },
    token: tokens.alpa_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 98,
    lpSymbol: 'ETH-CRETH2 LP',
    lpAddresses: {
      56: '',
      1: '0x71817445D11f42506F2D7F54417c935be90Ca731',
    },
    token: tokens.weth,
    quoteToken: tokens.creth2,
  },
  {
    pid: 99,
    lpSymbol: 'HBTC-CREAM LP',
    lpAddresses: {
      56: '',
      1: '0xb1D38026062Ac10FEDA072CA0E9b7E35f1f5795a',
    },
    token: tokens.dusd,
    quoteToken: tokens.weth,
  },

  {
    pid: 100,
    lpSymbol: 'ANT-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x201e6a9E75df132a8598720433Af35fe8d73e94D',
    },
    token: tokens.ant,
    quoteToken: tokens.weth,
  },
  {
    pid: 101,
    lpSymbol: 'COVER-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x66Ae32178640813F3c32a9929520BFE4Fef5D167',
    },
    token: tokens.cover,
    quoteToken: tokens.weth,
  },
  // {
  //   pid: 102,
  //   lpSymbol: 'HBTC-CREAM LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x049A1dF43ca577c1DB44A79CF673B443beED9F89',
  //   },
  //   token: tokens.hbtc,
  //   quoteToken: tokens.cream,
  // },

   {
    pid: 103,
    lpSymbol: 'WBTC-DIGG LP',
    lpAddresses: {
      56: '',
      1: '0x9a13867048e01c663ce8Ce2fE0cDAE69Ff9F35E3',
    },
    token: tokens.wbtc,
    quoteToken: tokens.digg,
  },
  {
    pid: 104,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x31d64f9403E82243e71C2af9D8F56C7DBe10C178',
    },
    token: tokens.nftx,
    quoteToken: tokens.weth,
  },

   {
    pid: 105,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xA8AEC03d5Cf2824fD984ee249493d6D4D6740E61',
    },
    token: tokens.api3,
    quoteToken: tokens.weth,
  },
  {
    pid: 106,
    lpSymbol: 'ETH-COMBO LP',
    lpAddresses: {
      56: '',
      1: '0x8Cd7DADc8E11c8706763E0DE7332f5Ea91E04E35',
    },
    token: tokens.weth,
    quoteToken: tokens.combo,
  },
  {
    pid: 107,
    lpSymbol: 'HBTC-CREAM LP',
    lpAddresses: {
      56: '',
      1: '0x51F5953659e7d63CF0EF60B8674eF819c225169e',
    },
    token: tokens.hbtc,
    quoteToken: tokens.cream,
  },
  {
    pid: 108,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x54bcf4948e32A8706C286416e3ced37284F17fc9',
    },
    token: tokens.mars,
    quoteToken: tokens.usdt_eth,
  },
  {
    pid: 109,
    lpSymbol: 'LDO-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xC558F600B34A5f69dD2f0D06Cb8A88d829B7420a',
    },
    token: tokens.ldo,
    quoteToken: tokens.weth,
  },
  {
    pid: 110,
    lpSymbol: 'BAC-BAB LP',
    lpAddresses: {
      56: '',
      1: '0x87bF6386f7611aFa452c642C2835a305a692607d',
    },
    token: tokens.bac,
    quoteToken: tokens.bab,
  },

  {
    pid: 111,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xBE1E98685fB293144325440C16f69954Ffcb790C',
    },
    token: tokens.weth,
    quoteToken: tokens.pwing,
  },
  {
    pid: 112,
    lpSymbol: 'TUSD-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x760166FA4f227dA29ecAC3BeC348f5fA853a1f3C',
    },
    token: tokens.tusd_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 113,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x7B98e476De2c50b6fa284DBd410Dd516f9a72b30',
    },
    token: tokens.usdt_eth,
    quoteToken: tokens.aethc,
  },
  {
    pid: 114,
    lpSymbol: 'SPANK-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x02C6260cE42Ea5cD055911ed0D4857eCD4583740',
    },
    token: tokens.spank,
    quoteToken: tokens.weth,
  },

  {
    pid: 115,
    lpSymbol: 'MTA-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x663242D053057f317A773D7c262B700616d0b9A0',
    },
    token: tokens.mta,
    quoteToken: tokens.weth,
  },
  {
    pid: 116,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x0Eee7f7319013df1f24F5eaF83004fCf9cF49245',
    },
    token: tokens.bao,
    quoteToken: tokens.weth,
  },
  {
    pid: 117,
    lpSymbol: 'ETH/RGT LP',
    lpAddresses: {
      56: '',
      1: '0x18A797C7C70c1Bf22fDee1c09062aBA709caCf04',
    },
    token: tokens.weth,
    quoteToken: tokens.rgt,
  },

  {
    pid: 118,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xA7f11E026a0Af768D285360a855F2BDEd3047530',
    },
    token: tokens.idle,
    quoteToken: tokens.weth,
  },
  {
    pid: 119,
    lpSymbol: 'USDT-aETHc LP',
    lpAddresses: {
      56: '',
      1: '0x2ee59d346e41478B9DC2762527fACF2082022A29',
    },
    token: tokens.usdt_eth,
    quoteToken: tokens.aethc,
  },
  {
    pid: 120,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x22DEF8cF4E481417cb014D9dc64975BA12E3a184',
    },
    token: tokens.sdt,
    quoteToken: tokens.weth,
  },
  {
    pid: 121,
    lpSymbol: 'GNO-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x41328fdBA556c8C969418ccCcB077B7B8D932aA5',
    },
    token: tokens.gno,
    quoteToken: tokens.weth,
  },
  {
    pid: 122,
    lpSymbol: 'BAC-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xFa8C3F98dEBF3d0a192e2EdF9182352332Def35c',
    },
    token: tokens.bac,
    quoteToken: tokens.weth,
  },
  {
    pid: 123,
    lpSymbol: 'ETH-aETHc LP',
    lpAddresses: {
      56: '',
      1: '0xfa5bc40c3BD5aFA8bC2fe6b84562fEE16FB2Df5F',
    },
    token: tokens.weth,
    quoteToken: tokens.aethc,
  },
  // {
  //   pid: 124,
  //   lpSymbol: 'SushiSwap LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xac63290A9D32CC01c7E2F5D02FC4225F843168A4',
  //   },
  //   token: tokens.weth,
  //   quoteToken: tokens.yld,
  // },
  // {
  //   pid: 125,
  //   lpSymbol: 'SushiSwap LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x9386d6EbbbB9F8002c5238dbD72b2e61Ad7D9011',
  //   },
  //   token: tokens.weth,
  //   quoteToken: tokens.yld,
  // },
  // {
  //   pid: 126,
  //   lpSymbol: 'SushiSwap LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xEd4290B3C49dF20319b3132f7007DCb3b0522e34',
  //   },
  //   token: tokens.weth,
  //   quoteToken: tokens.yld,
  // },
  {
    pid: 127,
    lpSymbol: 'ARMOR-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x17A2194D55f52Fd0C711e0e42B41975494bb109B',
    },
    token: tokens.armor,
    quoteToken: tokens.weth,
  },
  {
    pid: 128,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x46ACb1187a6d83e26c0bB46A57Ffeaf23Ad7851E',
    },
    token: tokens.weth,
    quoteToken: tokens.assy,
  },
  {
    pid: 129,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xf79a07cd3488BBaFB86dF1bAd09a6168D935c017',
    },
    token: tokens.alpha_eth,
    quoteToken: tokens.ibethv2,
  },
  {
    pid: 130,
    lpSymbol: 'renDOGE-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xb46736888247C68C995B156CA86426ff32e27Cc9',
    },
    token: tokens.rendoge,
    quoteToken: tokens.weth,
  },
  {
    pid: 131,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x0C48aE092A7D35bE0e8AD0e122A02351BA51FeDd',
    },
    token: tokens.nct,
    quoteToken: tokens.weth,
  },
  {
    pid: 132,
    lpSymbol: 'ETH-yveCRV-DAO LP',
    lpAddresses: {
      56: '',
      1: '0x10B47177E92Ef9D5C6059055d92DdF6290848991',
    },
    token: tokens.weth,
    quoteToken: tokens.yvecrvdao,
  },
  {
    pid: 133,
    lpSymbol: 'TORN-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xb270176bA6075196dF88B855c3Ec7776871Fdb33',
    },
    token: tokens.torn,
    quoteToken: tokens.weth,
  },
  {
    pid: 134,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xf5A434FbAA1C00b33Ea141122603C43dE86cc9FE',
    },
    token: tokens.mbtc,
    quoteToken: tokens.weth,
  },
  {
    pid: 135,
    lpSymbol: 'VSP-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x132eEb05d5CB6829Bd34F552cDe0b6b708eF5014',
    },
    token: tokens.vsp,
    quoteToken: tokens.weth,
  },
  {
    pid: 136,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xBbfd9B37ec6ea1cA612AB4ADef6d8c6ece1a4134',
    },
    token: tokens.weth,
    quoteToken: tokens.yld,
  },
  {
    pid: 137,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x1C615074c281c5d88ACc6914D408d7E71Eb894EE',
    },
    token: tokens.weth,
    quoteToken: tokens.steth,
  },
  {
    pid: 138,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x96F5b7C2bE10dC7dE02Fa8858A8f1Bd19C2fA72A',
    },
    token: tokens.dao,
    quoteToken: tokens.weth,
  },
  {
    pid: 139,
    lpSymbol: 'ETH-GRT LP',
    lpAddresses: {
      56: '',
      1: '0x7B504a15ef05F4EED1C07208C5815c49022A0C19',
    },
    token: tokens.weth,
    quoteToken: tokens.grt,
  },
  {
    pid: 140,
    lpSymbol: 'FTM-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x0E26A21013f2F8C0362cFae608b4e69a249D5EFc',
    },
    token: tokens.ftm_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 141,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xEc78bD3b23aC867FcC028f2db405A1d9A0A2f712',
    },
    token: tokens.weth,
    quoteToken: tokens.any,
  },
  {
    pid: 142,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x092493a22375DE1B17583D924aBf9e8bf884491C',
    },
    token: tokens.punk,
    quoteToken: tokens.weth,
  },
  {
    pid: 143,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xfd38565Ef22299D491055F0c508F62DD9a669F0F',
    },
    token: tokens.mask,
    quoteToken: tokens.weth,
  },
  {
    pid: 144,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x0267BD35789a5ce247Fff6CB1D597597e003cc43',
    },
    token: tokens.punkbasic,
    quoteToken: tokens.weth,
  },
  {
    pid: 145,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xCA2Ae9C5C491F497DC5625fEaef4572076C946C5',
    },
    token: tokens.punkfemale,
    quoteToken: tokens.weth,
  },
  {
    pid: 146,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x608f8af5fd49b5a5421f53f79920C45b96bdA83F',
    },
    token: tokens.punkattr4,
    quoteToken: tokens.weth,
  },
  {
    pid: 147,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xd54A895623552853F8D673981CC32EB8f3929dFB',
    },
    token: tokens.punkattr5,
    quoteToken: tokens.weth,
  },
  {
    pid: 148,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x0E7E8Dde18e4016ccc15F12301a47eF7B87Bdafa',
    },
    token: tokens.weth,
    quoteToken: tokens.punkzombie,
  },
  {
    pid: 149,
    lpSymbol: 'XFT-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xF39fF863730268C9bb867b3a69d031d1C1614b31',
    },
    token: tokens.xft,
    quoteToken: tokens.weth,
  },
  {
    pid: 150,
    lpSymbol: 'ETH-ZRX LP',
    lpAddresses: {
      56: '',
      1: '0x0BC5AE46c32D99C434b7383183ACa16DD6E9BdC8',
    },
    token: tokens.weth,
    quoteToken: tokens.zrx,
  },
  {
    pid: 151,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x3cf1Cf47Bc87C23cD9410549BD8a75E82C1c73cF',
    },
    token: tokens.usdn,
    quoteToken: tokens.weth,
  },
  {
    pid: 152,
    lpSymbol: 'DERI-USDT LP',
    lpAddresses: {
      56: '',
      1: '0xA3DfbF2933FF3d96177bde4928D0F5840eE55600',
    },
    token: tokens.deri_eth,
    quoteToken: tokens.usdt_eth,
  },
  {
    pid: 153,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x93E2F3a8277E0360081547D711446e4a1F83546D',
    },
    token: tokens.premia,
    quoteToken: tokens.weth,
  },
  {
    pid: 154,
    lpSymbol: 'BANK-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x938625591ADb4e865b882377e2c965F9f9b85E34',
    },
    token: tokens.bank,
    quoteToken: tokens.weth,
  },
  {
    pid: 155,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x38A0469520534fC70c9C0b9DE4B8649e36A2aE3E',
    },
    token: tokens.lina_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 156,
    lpSymbol: 'PERP-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x8486c538DcBD6A707c5b3f730B6413286FE8c854',
    },
    token: tokens.perp,
    quoteToken: tokens.weth,
  },
  {
    pid: 157,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x9c86BC3C72Ab97c2234CBA8c6c7069009465AE86',
    },
    token: tokens.wscrt,
    quoteToken: tokens.weth,
  },
  {
    pid: 158,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xB0484fB3aC155AaF7d024b20f1a569ddD6332c32',
    },
    token: tokens.usdp,
    quoteToken: tokens.weth,
  },
  {
    pid: 159,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xFe308FE2Eb938F772807AEc2E87Fc762d47c40E0',
    },
    token: tokens.usdp,
    quoteToken: tokens.duck,
  },
  {
    pid: 160,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xD3c41c080a73181e108E0526475a690F3616a859',
    },
    token: tokens.dextf,
    quoteToken: tokens.weth,
  },
  // {
  //   pid: 161,
  //   lpSymbol: 'SushiSwap LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x28D70B2d5ADa1d8de7f24711b812Fd7ab3C0fBc5',
  //   },
  //   token: tokens.bdigg_eth,
  //   quoteToken: tokens.weth,
  // },
  {
    pid: 162,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x1803a3386d44f65746403060aB0137682F554484',
    },
    token: tokens.onevbtc,
    quoteToken: tokens.weth,
  },
  {
    pid: 163,
    lpSymbol: '$TRDL-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x05Cc2e064e0B48e46015EAd9961F1391d74E5F83',
    },
    token: tokens.$trdl,
    quoteToken: tokens.weth,
  },
  {
    pid: 164,
    lpSymbol: 'RLC-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x75382c52b6F90B3f8014BfcadAC2386513F1e3bC',
    },
    token: tokens.rlc,
    quoteToken: tokens.weth,
  },
  {
    pid: 165,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xF9440fEDC72A0B8030861DcDac39A75b544E7A3c',
    },
    token: tokens.bdigg_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 166,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x0a54d4b378C8dBfC7bC93BE50C85DebAFdb87439',
    },
    token: tokens.bbadger_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 167,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x87B6f3A2DC6E541A9ce40E58f517953782Ae614E',
    },
    token: tokens.yla,
    quoteToken: tokens.usdc_eth,
  },
  {
    pid: 168,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x90825ADd1AD30d7DCeFEa12c6704A192be6eE94E',
    },
    token: tokens.punkbasic,
    quoteToken: tokens.nftx,
  },
  {
    pid: 169,
    lpSymbol: 'MASK-USDC LP',
    lpAddresses: {
      56: '',
      1: '0x31fa985bB0C282a814E7f3f0Dce88B2A44197F60',
    },
    token: tokens.mask,
    quoteToken: tokens.usdc_eth,
  },
  {
    pid: 170,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xf13eEF1C6485348B9C9FA0d5Df2d89AccC5b0147',
    },
    token: tokens.weth,
    quoteToken: tokens.rook,
  },
  {
    pid: 171,
    lpSymbol: 'SX-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x5e496B7D72362ADd1EEA7D4903Ee2732cD00587d',
    },
    token: tokens.sx,
    quoteToken: tokens.weth,
  },
  {
    pid: 172,
    lpSymbol: 'DFX-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xBE71372995E8e920E4E72a29a51463677A302E8d',
    },
    token: tokens.dfx,
    quoteToken: tokens.weth,
  },
  {
    pid: 173,
    lpSymbol: 'INV-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x328dFd0139e26cB0FEF7B0742B49b0fe4325F821',
    },
    token: tokens.inv,
    quoteToken: tokens.weth,
  },
  {
    pid: 174,
    lpSymbol: 'stFIRO-stETH LP',
    lpAddresses: {
      56: '',
      1: '0xB5c40E038c997c2946B24dC179F7CdcD279d8847',
    },
    token: tokens.stfiro,
    quoteToken: tokens.steth,
  },

  {
    pid: 175,
    lpSymbol: 'OCEAN-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xeE35E548C7457FcDd51aE95eD09108be660Ea374',
    },
    token: tokens.ocean,
    quoteToken: tokens.weth,
  },

  {
    pid: 176,
    lpSymbol: 'WOO-USDC LP',
    lpAddresses: {
      56: '',
      1: '0xf5ca27927Ffb16BD8C870Dcb49750146CCe8217c',
    },
    token: tokens.woo,
    quoteToken: tokens.usdc_eth,
  },
  {
    pid: 177,
    lpSymbol: 'PONT-PWING LP',
    lpAddresses: {
      56: '',
      1: '0x91A48c69Ec3f3cE855FE5054F82D2bef8Fd66C43',
    },
    token: tokens.pont,
    quoteToken: tokens.pwing,
  },
  {
    pid: 178,
    lpSymbol: 'DNT-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xa1f967F25AE32bD3435E45EA8657De16Ce5A4Ae6',
    },
    token: tokens.dnt,
    quoteToken: tokens.weth,
  },
  {
    pid: 179,
    lpSymbol: 'AERGO-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x9E48FaDf799E0513d2EF4631478ea186741fA617',
    },
    token: tokens.aergo,
    quoteToken: tokens.weth,
  },
  {
    pid: 180,
    lpSymbol: 'WBTC-POLY LP',
    lpAddresses: {
      56: '',
      1: '0x7835cB043e8d53a5b361D489956d6c30808349da',
    },
    token: tokens.wbtc,
    quoteToken: tokens.poly,
  },
  {
    pid: 181,
    lpSymbol: 'EGT-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xc7FF546c6CbC87Ad9f6F557db5A0df5c742cA440',
    },
    token: tokens.egt,
    quoteToken: tokens.weth,
  },

  // {
  //   pid: 182,
  //   lpSymbol: 'POOL-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x033f4A33823595A6dD9dF0672Fd94DE32C65c415',
  //   },
  //   token: tokens.poll,
  //   quoteToken: tokens.weth,
  // },
  {
    pid: 183,
    lpSymbol: 'MUST-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xA872D244B8948DFD6Cb7Bd19f79E7C1bfb7DB4a0',
    },
    token: tokens.must,
    quoteToken: tokens.weth,
  },

  {
    pid: 184,
    lpSymbol: 'MARK-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x750d711277Fd27D1EC5256F13f5110E097713a95',
    },
    token: tokens.mark,
    quoteToken: tokens.weth,
  },
  {
    pid: 185,
    lpSymbol: 'OHM-DAI LP',
    lpAddresses: {
      56: '',
      1: '0x34d7d7Aaf50AD4944B70B320aCB24C95fa2def7c',
    },
    token: tokens.ohm,
    quoteToken: tokens.dai_eth,
  },
  {
    pid: 186,
    lpSymbol: 'POOL-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x577959C519c24eE6ADd28AD96D3531bC6878BA34',
    },
    token: tokens.pool,
    quoteToken: tokens.weth,
  },
  {
    pid: 187,
    lpSymbol: 'YLD-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x662511a91734AEa8b06EF770D6Ed51cC539772d0',
    },
    token: tokens.weth,
    quoteToken: tokens.yld,
  },
  {
    pid: 188,
    lpSymbol: 'BZRX-ETH LP',
    lpAddresses: {
      56: '',
      1: '0xa30911e072A0C88D55B5D0A0984B66b0D04569d0',
    },
    token: tokens.bzrx,
    quoteToken: tokens.weth,
  },
  // {
  //   pid: 189,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x08Af656295e0EA970Fc4e35A75E62e5Aade3f9aF',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 190,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xfF7D29c7277D8A8850c473f0b71d7e5c4Af45A50',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 191,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xB7b45754167d65347C93F3B28797887b4b6cd2F3',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 192,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x5F92e4300024c447A103c161614E6918E794c764',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 193,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xf678B4A096bB49309b81B2a1c8a966Ef5F9756BA',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 194,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x418BC3ff0Ba33AD64931160A91C92fA26b35aCB0',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 195,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x668edab8A38A962D30602d6Fa7CA489484eE3224',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 196,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xC84Fb1F76cbdd3b3491E81FE3ff811248d0407e9',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 197,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x561770B93D0530390eb70e17AcBbD6E5d2f52A31',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },

  // {
  //   pid: 198,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x4f68e70e3a5308d759961643AfcadfC6f74B30f4',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 199,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x0a8D9241998C91747ce1672a0c31af2b33BD87bD',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 200,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x0a7cC061F0CCD2A1b6f07762663a9EC10720FCC5',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 201,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x002469ed116E8e2764F2E0869bd95317ee634334',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },

  // {
  //   pid: 202,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x0Ee6f615d982cC1Ad4Bb9AE3E9bf02ed5B68858E',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 203,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xF71e398B5CBb473a3378Bf4335256295A8eD713d',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },

  // {
  //   pid: 204,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x2bb55aAd93dEE6217Bb7887fe563718E5B5976cB',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 205,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x45DA3a59850525aAB0ACA23f0B11f442d4b74C85',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 206,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xCD769f48151Ca671f8545e40EFe03Ff7fFa157fb',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 207,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x795fEB1c35dc07991bfd23Ab74378885EC86c233',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 208,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x51d24429A72fa5Be5b588a0dE83a45F12fD57E57',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 209,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xA62D2E74D41d3477F7726E8f6A845269799dF573',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 210,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xC2A0254dfe1aC6d39Fe17f11eF1Af0A3faf87448',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 211,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xb5a98cd1030A7bB28716217e97D18Cfa2Aac4FC7',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 212,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x659B4642fF3d0719F71EaE903ce9f46B20767642',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 213,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x81f535Db4DF20271Be8Ab5caff29ba70B3709F90',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 214,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x891535966bEC70d447882C3eb1AB3926DDc5f788',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 215,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x82E9c2c3236De403509dE0472f2F3E16077Bc42d',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 216,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x663045D8ce1C61B9E0Af2C3469f17D3C152a66ba',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 217,
  //   lpSymbol: 'yvBOOST-ETH LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xb89313816cC3b2f9eC885860274C266355f2306D',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 218,
  //   lpSymbol: 'Kashi Medium Risk SushiToken/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x263716dEe5b74C5Baed665Cb19c6017e51296fa2',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 219,
  //   lpSymbol: 'Kashi Medium Risk SushiToken/Tether USD-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xF9a4e1e117818Fc98F9808f3DF4d7b72C0Df4160',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 220,
  //   lpSymbol: 'Kashi Medium Risk SushiToken/Dai Stablecoin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x05AD6d7dB640F4382184e2d82dD76b4581F8F8f4',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 221,
  //   lpSymbol: 'Kashi Medium Risk Wrapped Ether/1INCH Token-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xBe9081E742d9E24AA0584a4f77382062F033752F',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 222,
  //   lpSymbol: 'Kashi Medium Risk yearn.finance/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x65089e337109CA4caFF78b97d40453D37F9d23f8',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },

  // {
  //   pid: 223,
  //   lpSymbol: 'Kashi Medium Risk Aave Token/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x40a12179260997c55619DE3290c5b9918588E791',
  //   },
  //   token: tokens.yvboost,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 224,
  //   lpSymbol: 'Kashi Medium Risk AlphaToken/USD Coin-Chainlink ',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xa01f36655fc3Ae0f618b29943C4aC242D71F6f50',
  //   },
  //   token: tokens.bask,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 225,
  //   lpSymbol: 'Kashi Medium Risk Badger/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xa898974410F7e7689bb626B41BC2292c6A0f5694',
  //   },
  //   token: tokens.bask,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 226,
  //   lpSymbol: 'Kashi Medium Risk THORChain ETH.RUNE/Tether USD-Ch',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xDf55bD0a205ec067aB1CaCfaeef708cF1d93ECfd',
  //   },
  //   token: tokens.bask,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 227,
  //   lpSymbol: 'Kashi Medium Risk UMA Voting Token v1/Dai Stablecoin',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x809F2B68f59272740508333898D4e9432A839C75',
  //   },
  //   token: tokens.bask,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 228,
  //   lpSymbol: 'Kashi Medium Risk Dai Stablecoin/Curve DAO Token-ChainLink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x15785398CF9FB677ddcbDb1133585a4D0C65c2d9',
  //   },
  //   token: tokens.bask,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 229,
  //   lpSymbol: 'Kashi Medium Risk DefiPulse Index/Synth sUSD-ChainLink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xa16C84206A6b69C01833101133CC78A47602349D',
  //   },
  //   token: tokens.bask,
  //   quoteToken: tokens.weth,
  // },
  {
    pid: 230,
    lpSymbol: 'yvBOOST-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x9461173740D27311b176476FA27e94C681b1Ea6b',
    },
    token: tokens.yvboost,
    quoteToken: tokens.weth,
  },
  {
    pid: 231,
    lpSymbol: 'AXS-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x0C365789DbBb94A29F8720dc465554c587e897dB',
    },
    token: tokens.axs_eth,
    quoteToken: tokens.weth,
  },

  {
    pid: 232,
    lpSymbol: 'BDI-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x8d782C5806607E9AAFB2AC38c1DA3838Edf8BD03',
    },
    token: tokens.bdi,
    quoteToken: tokens.weth,
  },
  {
    pid: 233,
    lpSymbol: 'BASK-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x34D25a4749867eF8b62A0CD1e2d7B4F7aF167E01',
    },
    token: tokens.bask,
    quoteToken: tokens.weth,
  },
  {
    pid: 234,
    lpSymbol: 'BTC2x-FLI-WBTC LP',
    lpAddresses: {
      56: '',
      1: '0x164FE0239d703379Bddde3c80e4d4800A1cd452B',
    },
    token: tokens.btc2xfli,
    quoteToken: tokens.wbtc,
  },
  {
    pid: 235,
    lpSymbol: 'WBTC-bBTC LP',
    lpAddresses: {
      56: '',
      1: '0x18d98D452072Ac2EB7b74ce3DB723374360539f1',
    },
    token: tokens.wbtc,
    quoteToken: tokens.ibbtc,
  },
  {
    pid: 236,
    lpSymbol: 'ZIG-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x4Fb3CAe84a1264b8BB1911e8915F56660eC8178E',
    },
    token: tokens.zig,
    quoteToken: tokens.weth,
  },
  {
    pid: 237,
    lpSymbol: 'MM-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x41848373dec2867ef3924E47B2eBD0EE645a54F9',
    },
    token: tokens.mm,
    quoteToken: tokens.weth,
  },
  {
    pid: 238,
    lpSymbol: 'PEND-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x37922C69b08BABcCEaE735A31235c81f1d1e8E43',
    },
    token: tokens.pend,
    quoteToken: tokens.weth,
  },
  {
    pid: 239,
    lpSymbol: 'PMON-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x69ab811953499Eb253c5a69aE06275A42b97c9aE',
    },
    token: tokens.pmon,
    quoteToken: tokens.weth,
  },
  {
    pid: 240,
    lpSymbol: 'MANA-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x1bEC4db6c3Bc499F3DbF289F5499C30d541FEc97',
    },
    token: tokens.mana,
    quoteToken: tokens.weth,
  },

  {
    pid: 241,
    lpSymbol: 'WASABI-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x8F9ef75CD6E610Dd8Acf8611c344573032fB9c3d',
    },
    token: tokens.wasabi,
    quoteToken: tokens.weth,
  },
  {
    pid: 242,
    lpSymbol: 'DRC-WETH LP',
    lpAddresses: {
      56: '',
      1: '0xC79FAEed130816B38E5996b79B1b3b6568cc599F',
    },
    token: tokens.drc,
    quoteToken: tokens.weth,
  },
  {
    pid: 243,
    lpSymbol: 'ETH-$DG LP',
    lpAddresses: {
      56: '',
      1: '0xd3dA6236aEcb6b55F571249c011B8EEC340a418E',
    },
    token: tokens.weth,
    quoteToken: tokens.$dg,
  },
  {
    pid: 244,
    lpSymbol: 'ILV-ETH LP',
    lpAddresses: {
      56: '',
      1: '0x6a091a3406E0073C3CD6340122143009aDac0EDa',
    },
    token: tokens.ilv,
    quoteToken: tokens.weth,
  },
  // {
  //   pid: 245,
  //   lpSymbol: 'Kashi Medium Risk SushiBar/Wrapped UST Token-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x67AdC7432ce278486CC84FBc705BF70b5AB33a95',
  //   },
  //   token: tokens.blo,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 246,
  //   lpSymbol: 'Kashi Medium Risk SushiBar/Dai Stablecoin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x77F3A4Fa35BaC0EA6CfaC69037Ac4d3a757240A1',
  //   },
  //   token: tokens.blo,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 247,
  //   lpSymbol: 'Kashi Medium Risk SushiBar/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x6EAFe077df3AD19Ade1CE1abDf8bdf2133704f89',
  //   },
  //   token: tokens.blo,
  //   quoteToken: tokens.weth,
  // },

  // {
  //   pid: 248,
  //   lpSymbol: 'Kashi Medium Risk SushiBar/TrueUSD-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x3485A7C8913d640245e38564DDC05Bfb40104635',
  //   },
  //   token: tokens.blo,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 249,
  //   lpSymbol: 'Kashi Medium Risk SushiBar/Tether USD-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x17Fb5f39C55903DE60E63543067031cE2B2659EE',
  //   },
  //   token: tokens.blo,
  //   quoteToken: tokens.weth,
  // },


  // {
  //   pid: 250,
  //   lpSymbol: 'SushiSwap LP',
  //   lpAddresses: {
  //     56: '',
  //     1: '0xa5e3142b7a5D59F778483A7E0FD3Fe4e263388e9',
  //   },
  //   token: tokens.umb,
  //   quoteToken: tokens.weth,
  // },
  {
    pid: 251,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x804Be24f625C7E23eDd9Fa68e4582590c57ad2B3',
    },
    token: tokens.blo,
    quoteToken: tokens.weth,
  },
  {
    pid: 252,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x3bFcA4FB8054fA42DA3E77749b21450a1290beED',
    },
    token: tokens.ldn,
    quoteToken: tokens.weth,
  },
  {
    pid: 253,
    lpSymbol: 'ETH/WOOFY LP',
    lpAddresses: {
      56: '',
      1: '0x9AC60b8B33092C2c0B4CA5Af0DEC2bcb84657E12',
    },
    token: tokens.weth,
    quoteToken: tokens.woofy,
  },
  {
    pid: 254,
    lpSymbol: 'YFI/WOOFY LP',
    lpAddresses: {
      56: '',
      1: '0x0780B42B3c4cAF41933CFC0040d2853363De20A7',
    },
    token: tokens.yfi_eth,
    quoteToken: tokens.woofy,
  },
  {
    pid: 255,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x82EbCD936C9E938704b65027850E42393F8BC4d4',
    },
    token: tokens.naos,
    quoteToken: tokens.weth,
  },
  {
    pid: 256,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x7229d526d5fD693720B88Eb7129058dB5D497BCe',
    },
    token: tokens.umb,
    quoteToken: tokens.weth,
  },
  {
    pid: 257,
    lpSymbol: 'MOVE/ETH LP',
    lpAddresses: {
      56: '',
      1: '0x87b918e76c92818DB0c76a4E174447aeE6E6D23f',
    },
    token: tokens.move,
    quoteToken: tokens.weth,
  },
  {
    pid: 258,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xe73ad09925201F21b607ccADA9A371C12A2f49C3',
    },
    token: tokens.lev,
    quoteToken: tokens.weth,
  },
  {
    pid: 259,
    lpSymbol: 'ETH/MLN LP',
    lpAddresses: {
      56: '',
      1: '0x2F8AC927aa94293461C75406e90Ec0cCFb2748d9',
    },
    token: tokens.weth,
    quoteToken: tokens.mln,
  },
  {
    pid: 260,
    lpSymbol: 'RULER/ETH LP',
    lpAddresses: {
      56: '',
      1: '0xb1EECFea192907fC4bF9c4CE99aC07186075FC51',
    },
    token: tokens.ruler,
    quoteToken: tokens.weth,
  },
  // {
  //   pid: 261,
  //   lpSymbol: 'Kashi Medium Risk Republic Token/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x0d2606158fA76b38C5d58dB94B223C3BdCBbf57C',
  //   },
  //   token: tokens.bank,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 262,
  //   lpSymbol: 'Kashi Medium Risk Republic Token/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x6D6542B07241107B16C71C20F035f190CdA75237',
  //   },
  //   token: tokens.bank,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 263,
  //   lpSymbol: 'Kashi Medium Risk Republic Token/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x2bfD753982fF94f4D2503d6280A68FcA5DA114A7',
  //   },
  //   token: tokens.bank,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 264,
  //   lpSymbol: 'Kashi Medium Risk THORChain ETH.RUNE/Synth sUSD-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x94A5A6D050b030e3a6d5211a70aE502AFab98d73',
  //   },
  //   token: tokens.bank,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 265,
  //   lpSymbol: 'Kashi Medium Risk Republic Token/USD Coin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x51C8796563d9CF2b3D938362A9522F21dB2c690d',
  //   },
  //   token: tokens.bank,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 266,
  //   lpSymbol: 'Kashi Medium Risk Aave Token/Dai Stablecoin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x0EA032DEcBfbeA581d77D4A9B9c5E9dB7C102a7c',
  //   },
  //   token: tokens.bank,
  //   quoteToken: tokens.weth,
  // },
  // {
  //   pid: 267,
  //   lpSymbol: 'Kashi Medium Risk Uniswap/Dai Stablecoin-Chainlink',
  //   lpAddresses: {
  //     56: '',
  //     1: '0x651c7E8FA0aDd8c4531440650369533105113282',
  //   },
  //   token: tokens.bank,
  //   quoteToken: tokens.weth,
  // },
  {
    pid: 268,
    lpSymbol: 'BPT 2 LP',
    lpAddresses: {
      56: '',
      1: '0x57024267e8272618f9c5037D373043a8646507e5',
    },
    token: tokens.bpt,
    quoteToken: tokens.weth,
  },
  {
    pid: 269,
    lpSymbol: 'NEAR LP',
    lpAddresses: {
      56: '',
      1: '0x6469B34a2a4723163C4902dbBdEa728D20693C12',
    },
    token: tokens.near_eth,
    quoteToken: tokens.weth,
  },
  {
    pid: 270,
    lpSymbol: 'BANK 2 LP',
    lpAddresses: {
      56: '',
      1: '0x2c51eaa1BCc7b013C3f1D5985cDcB3c56DC3fbc1',
    },
    token: tokens.bank,
    quoteToken: tokens.weth,
  },
  {
    pid: 271,
    lpSymbol: 'alUSD-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x0589e281D35ee1Acf6D4fd32f1fbA60EFfb5281B',
    },
    token: tokens.alusd,
    quoteToken: tokens.weth,
  },
  {
    pid: 272,
    lpSymbol: 'USDC-SAK3 LP',
    lpAddresses: {
      56: '',
      1: '0xD45Afa3649e57a961C001b935deD1c79D81A9d23',
    },
    token: tokens.usdc_eth,
    quoteToken: tokens.sak3,
  },
  {
    pid: 273,
    lpSymbol: 'BOND/ETH LP',
    lpAddresses: {
      56: '',
      1: '0x613C836DF6695c10f0f4900528B6931441Ac5d5a',
    },
    token: tokens.bond,
    quoteToken: tokens.weth,
  },
  {
    pid: 274,
    lpSymbol: 'NDX-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x0BB6e2a9858A089437EC678dA05E559Ffe0Af5b2',
    },
    token: tokens.bb_cdai,
    quoteToken: tokens.dai_eth,
  },

  {
    pid: 275,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xA914a9b9E03b6aF84F9c6bd2e0e8d27D405695Db',
    },
    token: tokens.weth,
    quoteToken: tokens.fold,
  },
  {
    pid: 276,
    lpSymbol: 'NDX-WETH LP',
    lpAddresses: {
      56: '',
      1: '0x8911fce375a8414B1b578BE66eE691A8D2D4DBf7',
    },
    token: tokens.ndx,
    quoteToken: tokens.weth,
  },
  {
    pid: 277,
    lpSymbol: 'DEGEN LP',
    lpAddresses: {
      56: '',
      1: '0xe8eB0f7B866A85DA49401D04FfFcfC1aBbF24Dfd',
    },
    token: tokens.degen,
    quoteToken: tokens.weth,
  },
  {
    pid: 278,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0x986627dB5E4AAE987f580feB63D475992e5aC0AE',
    },
    token: tokens.weth,
    quoteToken: tokens.one1,
  },
  {
    pid: 279,
    lpSymbol: 'USDC-CQT LP',
    lpAddresses: {
      56: '',
      1: '0x17890DeB188F2dE6C3e966e053dA1C9a111Ed4A5',
    },
    token: tokens.usdc_eth,
    quoteToken: tokens.cqt,
  },
  {
    pid: 280,
    lpSymbol: 'USDT/DVF LP',
    lpAddresses: {
      56: '',
      1: '0xe93b1b5E1dAdCE8152A69470C1b31463aF260296',
    },
    token: tokens.dvf,
    quoteToken: tokens.usdt_eth,
  },
  {
    pid: 281,
    lpSymbol: 'ANKR/ETH LP',
    lpAddresses: {
      56: '',
      1: '0x1241F4a348162d99379A23E73926Cf0bfCBf131e',
    },
    token: tokens.ankr,
    quoteToken: tokens.weth,
  },
  {
    pid: 282,
    lpSymbol: 'ONX LP',
    lpAddresses: {
      56: '',
      1: '0x0652687E87a4b8b5370b05bc298Ff00d205D9B5f',
    },
    token: tokens.onix,
    quoteToken: tokens.weth,
  },

  {
    pid: 283,
    lpSymbol: 'ibEUR LP',
    lpAddresses: {
      56: '',
      1: '0xa2D81bEdf22201A77044CDF3Ab4d9dC1FfBc391B',
    },
    token: tokens.ibeur,
    quoteToken: tokens.weth,
  },
  {
    pid: 284,
    lpSymbol: 'EDEN 2 LP',
    lpAddresses: {
      56: '',
      1: '0x82DBc2673e9640343D263a3c55DE49021AD39aE2',
    },
    token: tokens.eden,
    quoteToken: tokens.weth,
  },
  {
    pid: 285,
    lpSymbol: 'TOWER LP',
    lpAddresses: {
      56: '',
      1: '0xdBaa04796CB5C05D02B8A41B702d9b67c13c9fa9',
    },
    token: tokens.tower,
    quoteToken: tokens.weth,
  },
  {
    pid: 286,
    lpSymbol: 'DPI-FEI LP',
    lpAddresses: {
      56: '',
      1: '0x8775aE5e83BC5D926b6277579c2B0d40c7D9b528',
    },
    token: tokens.dpi,
    quoteToken: tokens.fei,
  },
  {
    pid: 287,
    lpSymbol: 'XYZ-USDC LP',
    lpAddresses: {
      56: '',
      1: '0xBBBdB106A806173d1eEa1640961533fF3114d69A',
    },
    token: tokens.xyz,
    quoteToken: tokens.usdc_eth,
  },
  {
    pid: 288,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xb90047676cC13e68632c55cB5b7cBd8A4C5A0A8E',
    },
    token: tokens.vusd,
    quoteToken: tokens.weth,
  },

  {
    pid: 289,
    lpSymbol: 'SushiSwap LP',
    lpAddresses: {
      56: '',
      1: '0xada8B1613ce6Fe75f3549Fa4eB2A993ca1220A7c',
    },
    token: tokens.bank,
    quoteToken: tokens.weth,
  },

]

export default farms_sushiswap;

