import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'

import BigNumber from 'bignumber.js'

import { useFarms, usePollFarmsData, usePriceCakeBusd, useFarms_ape, usePriceBananaBusd, useFarms_sushi, usePriceSushiBusd } from 'state/hook';
import { Farm } from 'state/types';
import { getFarmApr, getFarmApr_ape, getFarmApr_sushi } from 'utils/apr';
import isArchivedPid from 'utils/farmHelpers';

import { orderBy } from 'lodash';
import { getBalanceAmount, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
// import { useLocation, useRouteMatch } from 'react-router-dom';
interface FarmWithStakedValue extends Farm {
  apr?: number
  liquidity?: BigNumber
}

const NUMBER_OF_FARMS_VISIBLE = 12
export default function FarmCard(props) {


  const [sortOption, setSortOption] = useState('hot')

  //const [farms, setfarms] = useState([])
  //     const rewardLP= useAllEarnings();
  //     const balanceLP = useLPBalance();


  const { data: farmsLP, userDataLoaded } = useFarms()
  const { data: farmsLP_ape } = useFarms_ape()
  const { data: farmsLP_sushi } = useFarms_sushi()

//console.log("farmLP_ape",farmsLP_ape);
  console.log("farmsushi",farmsLP_sushi);
  const cakePrice = usePriceCakeBusd()
  const bananaPrice = usePriceBananaBusd()
  const sushiPrice = usePriceSushiBusd()
 console.log("sushiPrice",sushiPrice.toNumber());
  const isActive = true
  usePollFarmsData(props.data[props.addcount])

  const [stakedOnly, setStakedOnly] = useState(!isActive)
  useEffect(() => {
    setStakedOnly(!isActive)
  }, [isActive])
  const activeFarms = farmsLP.filter((farm) => farm.pid !== 0 && farm.multiplier !== '0X' && !isArchivedPid(farm.pid))
  const inactiveFarms = farmsLP.filter((farm) => farm.pid !== 0 && farm.multiplier === '0X' && !isArchivedPid(farm.pid))
  const archivedFarms = farmsLP.filter((farm) => isArchivedPid(farm.pid))
  const activeFarms_ape = farmsLP_ape.filter((farm) => farm.pid !== 0 && farm.multiplier !== '0X' && !isArchivedPid(farm.pid))
  const inactiveFarms_ape = farmsLP_ape.filter((farm) => farm.pid !== 0 && farm.multiplier === '0X' && !isArchivedPid(farm.pid))
  const archivedFarms_ape = farmsLP_ape.filter((farm) => isArchivedPid(farm.pid))
  const activeFarms_sushi = farmsLP_sushi.filter((farm) => farm.pid !== 0 && farm.multiplier !== '0X' && !isArchivedPid(farm.pid))
  const inactiveFarms_sushi = farmsLP_sushi.filter((farm) => farm.pid !== 0 && farm.multiplier === '0X' && !isArchivedPid(farm.pid))
  const archivedFarms_sushi = farmsLP_sushi.filter((farm) => isArchivedPid(farm.pid))

  const stakedOnlyFarms = activeFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedInactiveFarms = inactiveFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedArchivedFarms = archivedFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedOnlyFarms_ape = activeFarms_ape.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedInactiveFarms_ape = inactiveFarms_ape.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedArchivedFarms_ape = archivedFarms_ape.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )
  const stakedOnlyFarms_sushi = activeFarms_sushi.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedInactiveFarms_sushi = inactiveFarms_sushi.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedArchivedFarms_sushi = archivedFarms_sushi.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )
  const farmsList = useCallback((farmsToDisplay: Farm[]): FarmWithStakedValue[] => {

    let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {

      if (!farm.lpTotalInQuoteToken || !farm.quoteToken.busdPrice) {

        return farm
      }
      const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(farm.quoteToken.busdPrice)
      // console.log(farm.quoteToken.busdPrice);
      // console.log( new BigNumber(farm.lpTotalInQuoteToken));
      const apr = isActive ? getFarmApr(new BigNumber(farm.poolWeight), cakePrice, totalLiquidity) : 0

      return { ...farm, apr, liquidity: totalLiquidity }
    })


    return farmsToDisplayWithAPR
  },
    [cakePrice, isActive],
  )
  const farmsList_ape = useCallback((farmsToDisplay: Farm[]): FarmWithStakedValue[] => {

    let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {

      if (!farm.lpTotalInQuoteToken || !farm.quoteToken.busdPrice) {

        return farm
      }
      const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(farm.quoteToken.busdPrice)

      // console.log(farm.quoteToken.busdPrice);
      // console.log( new BigNumber(farm.lpTotalInQuoteToken));
      const apr = isActive ? getFarmApr_ape(new BigNumber(farm.poolWeight), bananaPrice, totalLiquidity) : 0

      return { ...farm, apr, liquidity: totalLiquidity }
    })

    return farmsToDisplayWithAPR
  },
    [isActive, bananaPrice],
  )

  const farmsList_sushi = useCallback((farmsToDisplay: Farm[]): FarmWithStakedValue[] => {

    let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
    
      if (!farm.lpTotalInQuoteToken || !farm.quoteToken.busdPrice) {

        return farm
      }
      const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(farm.quoteToken.busdPrice)

      // console.log(farm.quoteToken.busdPrice);
      // console.log( new BigNumber(farm.lpTotalInQuoteToken));
      const apr = isActive ? getFarmApr_sushi(new BigNumber(farm.poolWeight), sushiPrice, totalLiquidity) : 0
     
      return { ...farm, apr, liquidity: totalLiquidity }
    })
    return farmsToDisplayWithAPR
  },
    [isActive, sushiPrice],
  )
  const [numberOfFarmsVisible, setNumberOfFarmsVisible] = useState(NUMBER_OF_FARMS_VISIBLE)
  const farmsStakedMemoized = useMemo(() => {
    let farmsStaked = []
    let farmsStaked_ape = []
    let farmsStaked_sushi = []
    //  console.log("1");
    const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
      switch (sortOption) {
        case 'apr':
          return orderBy(farms, (farm: FarmWithStakedValue) => farm.apr, 'desc')
        case 'multiplier':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0),
            'desc',
          )
        case 'earned':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.userData ? Number(farm.userData.earnings) : 0),
            'desc',
          )
        case 'liquidity':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
        default:
          return farms
      }
    }

    farmsStaked = true ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
    farmsStaked_ape = true ? farmsList_ape(stakedOnlyFarms_ape) : farmsList_ape(activeFarms_ape)
    farmsStaked_sushi = true ? farmsList_sushi(stakedOnlyFarms_sushi) : farmsList_sushi(activeFarms_sushi)
   
    //console.log("farmssushi",farmsStaked_sushi);
    farmsStaked.push(...farmsStaked_ape)
    farmsStaked.push(...farmsStaked_sushi)


    return sortFarms(farmsStaked).slice(0, numberOfFarmsVisible)
  }, [
    sortOption,
    activeFarms,
    farmsList,
    inactiveFarms,
    archivedFarms,
    isActive,

    stakedArchivedFarms,
    stakedInactiveFarms,
    stakedOnly,
    stakedOnlyFarms,
    numberOfFarmsVisible,

    stakedOnlyFarms_ape,
    stakedInactiveFarms_ape,
    stakedArchivedFarms_ape,

    stakedOnlyFarms_sushi,
    stakedInactiveFarms_sushi,
    stakedArchivedFarms_sushi
  ])

  // console.log(farmsStakedMemoized);

  function togglePopup() {
    (document.getElementById("popup-1") as HTMLFormElement).classList.toggle(
      "active"
    );
  }


  return (



   
    <div className="rounded-3 mt-2 mb-4 bg-box p-2" >

      <div className='text-start mt-4 mb-4'><h2 className='fs-5'>FARM ASSETS</h2></div>
      <div className='d-flex justify-content-end mb-3'><h2>VALUE</h2></div>
      <div style={{ height: '200px', overflow: 'auto', overflowX: 'hidden' }}>
        {farmsStakedMemoized.map((farm, index) => {
          const Reward = getBalanceAmount(new BigNumber(farm.userData.earnings)).toNumber()
          const Value = parseFloat(farm.token.busdPrice)
          const Staked = getBalanceAmount(new BigNumber(farm.userData.stakedBalance)).toNumber()
          const total = (Reward + Staked) * Value
          return <div className='row' key={index} >
            <div className='d-flex flex-row justify-content-between'>
              <div className='d-flex img-token  align-items-center justify-content-start' style={{ columnGap: '5px' }}>
                <div >
                  <img className='img-quoteToken' src={`images/tokens/${farm.quoteToken.address[56]}.png`} alt="" />
                  <img className='img-Token' src={`images/tokens/${farm.token.address[56]}.png`} alt="" />
                </div>
                <div className='d-flex flex-column' style={{ rowGap: '10px' }}>
                  <h3>{farm.lpSymbol}</h3>
                </div>

              </div>
              <div className='mt-3'>
                <h3>{total.toFixed(2)}{' '}$</h3>

              </div>
            </div>

            {/* <div className='d-flex justify-content-between mt-3'><h3>Earned:</h3> {Reward.toFixed(3, BigNumber.ROUND_DOWN)}</div>
            <div className='d-flex justify-content-between mt-3'><h3>Staked:</h3>{Staked.toFixed(8, BigNumber.ROUND_DOWN)}</div>
            <div className='d-flex justify-content-between mt-3'><h3>Value:</h3>{Value}</div>
        <div className='linear-card'></div>
      
            <div className='text-center mb-2' style={{cursor:'not-allowed'}}>Details</div>  */}




          </div>
        }
        )}

      </div>
      <div className='d-flex flex-row justify-content-center'> <button className='btn btn-1' onClick={togglePopup}>more detail</button></div>

      <div>
        <div className="popup " id="popup-1">
          <div className="overlay" onClick={togglePopup} />
          <div className="content d-flex flex-column " style={{ borderRadius: '20px' }}>


            <div className='row'>
              <div className='col-2 d-flex flex-row justify-content-center farm-detail'><h4>FARM</h4></div>
              <div className='col-2 d-flex flex-row justify-content-center farm-detail'><h4>VALUE</h4></div>
              <div className='col-3 d-flex flex-row justify-content-center farm-detail'><h4>REWARD</h4></div>
              <div className='col-2 d-flex flex-row justify-content-center farm-detail'><h4>APR(%)</h4></div>
              <div className='col-3 d-flex flex-row justify-content-center farm-detail'><h4>STAKED</h4></div>
            </div>
            <div className='row' style = {{overflow: 'scroll', overflowX: 'hidden'}}>
              {farmsStakedMemoized.map((farm, index) => {
                const Reward = getBalanceAmount(new BigNumber(farm.userData.earnings)).toNumber()
                const Value = parseFloat(farm.token.busdPrice)
                const Staked = getBalanceAmount(new BigNumber(farm.userData.stakedBalance))
                const total = (Reward + Staked.toNumber()) * Value
                const apr = farm.apr?farm.apr.toFixed(2):0

                return <div className='row mt-2 ' style={{ backgroundColor: '#322B67' }} key={index} >

                  <div className='col-2 d-flex flex-row justify-content-center farm-detail' style={{ alignItems: 'center', columnGap: '5px' }}>

                    <div style={{position:'relative'}}>
                      <img className='img-quoteToken' src={`images/tokens/${farm.quoteToken.address[56]}.png`} alt="" />
                      <img className='img-Token' src={`images/tokens/${farm.token.address[56]}.png`} alt="" />
                    </div>

                    <h3>{farm.lpSymbol}</h3>

                  </div>
                  <div className='col-2 d-flex flex-row justify-content-center farm-detail' style={{ alignItems: 'center' }}>

                    <h3 style={{ paddingLeft: '15px' }}>{total.toFixed(2)}{' '}$</h3>

                  </div>
                  <div className='col-3  d-flex flex-row justify-content-center farm-detail' style={{ alignItems: 'center' }}>

                    <h3 style={{ paddingLeft: '17px' }}>  {Reward}{'  '}{farm.token.symbol}</h3>
                  </div>
                  <div className='col-2 d-flex flex-row justify-content-center farm-detail' style={{ alignItems: 'center' }}><h3 style={{ paddingLeft: '32px' }}>{apr}</h3></div>
                  <div className='col-3 d-flex flex-row justify-content-center farm-detail' style={{ alignItems: 'center' }}><h3 style={{paddingLeft: '36px' }}>{Staked.toFixed(8, BigNumber.ROUND_DOWN)}</h3></div>




                </div>
              }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>





  )
}
