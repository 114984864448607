export default {
    masterChef: {
      97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    masterchef_dopple:{
      97:'',
      56:'0xDa0a175960007b0919DBF11a38e6EC52896bddbE'
    },
    masterchef_ape:{
      97:'',
      56:'0x5c8D727b265DBAfaba67E050f2f739cAeEB4A6F9'
    },
    masterchef_sushiswap:{
      1:'0xc2EdaD668740f1aA35E4D8f227fB8E17dcA888Cd',
      56:''
    },
    sousChef: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
    },
    lottery: {
      97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
      56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
    },
    lotteryNFT: {
      97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
      56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
    },
    lotteryV2: {
      97: '0x4D8B718b4e617ea49bCe6B508c23353Cc7bADd28',
      56: '',
    },

    multiCall: {
      97: '0xceBd462e1Ab91B648372D1BC507E712B8c4c9AbB',
      56: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    },

    multiCall_Mainnet: {
     
      56: '',
      1: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441'
    },
    pancakeProfile: {
      56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
      97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    },
    pancakeRabbits: {
      56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
      97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    },
    bunnyFactory: {
      56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
      97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    },
    claimRefund: {
      56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
      97: '',
    },
    pointCenterIfo: {
      56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
      97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    },
    bunnySpecial: {
      56: '0xFee8A195570a18461146F401d6033f5ab3380849',
      97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    },
    tradingCompetition: {
      56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
      97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    },
    easterNft: {
      56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
      97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    },
    cakeVault: {
      56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
      97: '',
    },
    predictions: {
      56: '0x516ffd7D1e0Ca40b1879935B2De87cb20Fc1124b',
      97: '0x4f3140C74789F1D809420343ea83BcE52B7bbAA5',
    },
    chainlinkOracle: {
      56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
      97: '',
    },
    cakeBalance:{
      56:'0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      97:''
    },
    jamBalance1:{
      97:'0x318d2dAB03dC2D98745Af9E0774265Bc22A5Ac6B',
      56:''
    },
    jamBalance2:{
      97:'0x653DeC78219370D84e4d66f2cB9136B923C02695',
      56:''
    }

  }
  