import { Token2 } from './types'

export const token : Token2[] =[

{
    symbol: 'VSNT',
    address: {
      56: '0x42577772e7bDC777C809CADEA681563e2Ba7a7f5',
      97: '',
    },
    
    decimals: 9,
  },]