import Web3 from 'web3'
import { HttpProviderOptions } from 'web3-core-helpers'
import { ARCHIVED_NODE } from '../config/constants/endpoints'
import getRpcUrl from './getRpcUrl'

const RPC_URL = getRpcUrl() 

const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions)
const httpProvider_mainnet = new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/fd672bc8630c492b9582c2541095d4c4", { timeout: 20000 } as HttpProviderOptions)
const web3NoAccount = new Web3(httpProvider)
export const web3NoAccount_mainnet = new Web3(httpProvider_mainnet)

const archivedHttpProvider = new Web3.providers.HttpProvider(ARCHIVED_NODE, { timeout: 10000 } as HttpProviderOptions)
export const web3WithArchivedNodeProvider = new Web3(archivedHttpProvider)

export default web3NoAccount
