import tokens from './tokens'
import { FarmConfig } from './types'

const farms_ape: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'BANANA',
    lpAddresses: {
      97: '',
      56: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 1,
    lpSymbol: 'BANANA-WBNB',
    lpAddresses: {
      97: '',
      56: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    },
    token: tokens.banana,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BANANA-BUSD',
    lpAddresses: {
      97: '',
      56: '0x7Bd46f6Da97312AC2DBD1749f82E202764C0B914',
    },
    token: tokens.busd,
    quoteToken: tokens.banana,
  },
  {
    pid: 3,
    lpSymbol: 'BUSD-BNB',
    lpAddresses: {
      97: '',
      56: '0x51e6D27FA57373d8d4C256231241053a70Cb1d93',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 4,
    lpSymbol: 'BTCB-WBNB',
    lpAddresses: {
      97: '',
      56: '0x1E1aFE9D9c5f290d8F6996dDB190bd111908A43D',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'ETH-WBNB',
    lpAddresses: {
      97: '',
      56: '0xA0C3Ef24414ED9C9B456740128d8E63D016A9e11',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 6,
    lpSymbol: 'CAKE-BANANA',
    lpAddresses: {
      97: '',
      56: '0x9949E1DB416a8a05A0cAC0bA6Ea152ba8729e893',
    },
    token: tokens.cake,
    quoteToken: tokens.banana,
  },
  {
    pid: 7,
    lpSymbol: 'BANANA-BAKE',
    lpAddresses: {
      97: '',
      56: '0x51bB531A5253837A23cE8de478a4941A71A4202C',
    },
    token: tokens.banana,
    quoteToken: tokens.bake,
  },
  {
    pid: 8,
    lpSymbol: 'USDC-BUSD',
    lpAddresses: {
      97: '',
      56: '0xC087C78AbaC4A0E900a327444193dBF9BA69058E',
    },
    token: tokens.usdc,
    quoteToken: tokens.busd,
  },
  {
    pid: 9,
    lpSymbol: 'BANANA-BREW',
    lpAddresses: {
      97: '',
      56: '0x5514E0E1DA40A38E19d58e8B6E16226E16e183fA',
    },
    token: tokens.banana,
    quoteToken: tokens.brew,
  },
  {
    pid: 10,
    lpSymbol: 'BANANA-BIFI',
    lpAddresses: {
      97: '',
      56: '0x2ce820319047c407cb952060Df5f7fb3D9A9a688',
    },
    token: tokens.banana,
    quoteToken: tokens.bifi,
  },
  {
    pid: 11,
    lpSymbol: 'WBNB-BIFI',
    lpAddresses: {
      97: '',
      56: '0xDDd3f9d5Bd347c55D18752c0C2075698EC657750',
    },
    token: tokens.bifi,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 12,
    lpSymbol: 'BANANA-SUSHI',
    lpAddresses: {
      97: '',
      56: '0xdbcdA7B58c2078fcc790dD7C2c7272EdB7EAa2b0',
    },
    token: tokens.banana,
    quoteToken: tokens.sushi,
  },
  {
    pid: 13,
    lpSymbol: 'SUSHI-WBNB',
    lpAddresses: {
      97: '',
      56: '0x1D0C3044eBf055986c52D38b19B5369374E6Bc6A',
    },
    token: tokens.sushi,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 14,
    lpSymbol: 'ETH-SUSHI',
    lpAddresses: {
      97: '',
      56: '0x044F2b275A344D4edfc3d98e1cb7c02B30e6484e',
    },
    token: tokens.eth,
    quoteToken: tokens.sushi,
  },
  {
    pid: 15,
    lpSymbol: 'BANANA-GFCE',
    lpAddresses: {
      97: '',
      56: '0x9C87cae57f0962997d9bd66C24f3425d20543e3d',
    },
    token: tokens.banana,
    quoteToken: tokens.gfce,
  },
  {
    pid: 16,
    lpSymbol: 'GFCE-WBNB',
    lpAddresses: {
      97: '',
      56: '0xD7903933B10504a7C67f191285a6A7E5A233fD3B',
    },
    token: tokens.gfce,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 17,
    lpSymbol: 'ADA-WBNB',
    lpAddresses: {
      97: '',
      56: '0x40d4543887E4170A1A40Cd8dB15A6b297c812Cb1',
    },
    token: tokens.ada,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 18,
    lpSymbol: 'ADA-ETH',
    lpAddresses: {
      97: '',
      56: '0x61FE209E404166a53Cc627d0ae30A65606315dA7',
    },
    token: tokens.ada,
    quoteToken: tokens.eth,
  },
  {
    pid: 19,
    lpSymbol: 'NAUT-BANANA',
    lpAddresses: {
      97: '',
      56: '0xf579A6196d6CC8c2C40952Ece57345AbbD589c91',
    },
    token: tokens.naut,
    quoteToken: tokens.banana,
  },
  {
    pid: 20,
    lpSymbol: 'BAT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x6e425B4fc4Efd070Dc0deF1654a17946C7e6b3C4',
    },
    token: tokens.bat,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 21,
    lpSymbol: 'BAT-ETH',
    lpAddresses: {
      97: '',
      56: '0x85D87C038917eC8949f12B06262bB9d7a1290DB6',
    },
    token: tokens.bat,
    quoteToken: tokens.eth,
  },
  {
    pid: 22,
    lpSymbol: 'IOTA-WBNB',
    lpAddresses: {
      97: '',
      56: '0x0D70924695B6Ae496F0A74A36bf79d47307dD519',
    },
    token: tokens.iota,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 23,
    lpSymbol: 'ROCKET-WBNB',
    lpAddresses: {
      97: '',
      56: '0x93fa1A6357De25031311f784342c33A26Cb1C87A',
    },
    token: tokens.rocket,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 24,
    lpSymbol: 'NAUT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x288EA5437c7aaD045a393cee2F41E548df24d1C8',
    },
    token: tokens.naut,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 25,
    lpSymbol: 'CAKE-WBNB',
    lpAddresses: {
      97: '',
      56: '0x60593Abea55e9Ea9d31c1b6473191cD2475a720D',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 26,
    lpSymbol: 'BAKE-WBNB',
    lpAddresses: {
      97: '',
      56: '0xc1C7a1D33B34019F82808F64bA07e77512a13d1A',
    },
    token: tokens.bake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 27,
    lpSymbol: 'JDI-WBNB',
    lpAddresses: {
      97: '',
      56: '0xfb6063f29AF6dcd1fc03A8E221c6D91DEabbE764',
    },
    token: tokens.jdi,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 28,
    lpSymbol: 'NUTS-WBNB',
    lpAddresses: {
      97: '',
      56: '0x789fd04BFbC64169104466Ee0d48716E0452Bcf6',
    },
    token: tokens.nuts,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 29,
    lpSymbol: 'SWAMP-WBNB',
    lpAddresses: {
      97: '',
      56: '0xA3f0F63268DF562C71051AC5e81460e857C32c12',
    },
    token: tokens.swamp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 30,
    lpSymbol: 'KEYFI-WBNB',
    lpAddresses: {
      97: '',
      56: '0x7A8ACAEAfC4Fa051De4EAbff8D1abdD0388aE08a',
    },
    token: tokens.keyfi,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 31,
    lpSymbol: 'ONT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x924D3f2F94618e8Ee837d4C2b8d703F0de12a57e',
    },
    token: tokens.ont,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 32,
    lpSymbol: 'XRP-WBNB',
    lpAddresses: {
      97: '',
      56: '0x6f0f18f5fcC1466ec41c8106689e10BeFE68D1c0',
    },
    token: tokens.xrp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 33,
    lpSymbol: 'DOT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x21CBb561c5d7D70e9E6Cc42136CB22F07552aEef',
    },
    token: tokens.dot,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 34,
    lpSymbol: 'BSCUSD-BUSD',
    lpAddresses: {
      97: '',
      56: '0x2e707261d086687470B515B320478Eb1C88D49bb',
    },
    token: tokens.bscusd,
    quoteToken: tokens.busd,
  },
  {
    pid: 35,
    lpSymbol: 'SXP-WBNB',
    lpAddresses: {
      97: '',
      56: '0xF726b3e81FA7166B9C2cFd7eB7fe8CcBcb6B1355',
    },
    token: tokens.sxp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 36,
    lpSymbol: 'LINK-WBNB',
    lpAddresses: {
      97: '',
      56: '0x092ADA3818DB7FBb8e0A2124Ff218C5125C1CcE6',
    },
    token: tokens.link,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 37,
    lpSymbol: 'BFT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x1696A65eA693593Ba771b5A7aFC54C8eaf4C20dE',
    },
    token: tokens.bft,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 38,
    lpSymbol: 'DOGE-WBNB',
    lpAddresses: {
      97: '',
      56: '0xfd1ef328A17A8e8Eeaf7e4Ea1ed8a108E1F2d096',
    },
    token: tokens.doge,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 39,
    lpSymbol: 'LTC-WBNB',
    lpAddresses: {
      97: '',
      56: '0x0F12362c017Fe5101c7bBa09390f1CB729f5B318',
    },
    token: tokens.ltc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 40,
    lpSymbol: 'DAI-BUSD',
    lpAddresses: {
      97: '',
      56: '0x8b6EcEA3e9bd6290c2150A89AF6c69887AaF1870',
    },
    token: tokens.dai,
    quoteToken: tokens.busd,
  },
  {
    pid: 41,
    lpSymbol: 'AUTO-WBNB',
    lpAddresses: {
      97: '',
      56: '0x649A5Ad5135B4bd287e5AcA8d41f4d5e1b52af5C',
    },
    token: tokens.auto,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 42,
    lpSymbol: 'BXBTC-WBNB',
    lpAddresses: {
      97: '',
      56: '0xc2feF4BEC915315beF9f6E8a06b2516E64D29D06',
    },
    token: tokens.bxbtc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 43,
    lpSymbol: 'VBSWAP-WBNB',
    lpAddresses: {
      97: '',
      56: '0xD59b4f88Da3b5cfc70CdF9B61c53Df475d4D4f47',
    },
    token: tokens.vbswap,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 44,
    lpSymbol: 'WATCH-WBNB',
    lpAddresses: {
      97: '',
      56: '0xa00A91fBB39051e2a6368424A93895c3f1F2290b',
    },
    token: tokens.watch,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 45,
    lpSymbol: 'MATIC-WBNB',
    lpAddresses: {
      97: '',
      56: '0x29A4A3D77c010CE100A45831BF7e798f0f0B325D',
    },
    token: tokens.matic,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 46,
    lpSymbol: 'AAVE-WBNB',
    lpAddresses: {
      97: '',
      56: '0xf13e007e181A8F57eD3a4D4CcE0A9ff9E7241CEf',
    },
    token: tokens.aave,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 47,
    lpSymbol: 'ETC-WBNB',
    lpAddresses: {
      97: '',
      56: '0xDd6C7A955C72B3FFD546d8dadBf7669528d8F785',
    },
    token: tokens.etc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 48,
    lpSymbol: 'COMP-WBNB',
    lpAddresses: {
      97: '',
      56: '0xb4c0c621B2eDfE6C22585ebAC56b0e634907B8A7',
    },
    token: tokens.comp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 49,
    lpSymbol: 'FTM-WBNB',
    lpAddresses: {
      97: '',
      56: '0x47A0B7bA18Bb80E4888ca2576c2d34BE290772a6',
    },
    token: tokens.ftm,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 50,
    lpSymbol: 'NRV-WBNB',
    lpAddresses: {
      97: '',
      56: '0x876Ba49c4F438643Ab33F871e14a54CBb897Df49',
    },
    token: tokens.nrv,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 51,
    lpSymbol: 'AVAX-WBNB',
    lpAddresses: {
      97: '',
      56: '0x40aFc7CBd0Dc2bE5860F0035b717d20Afb4827b2',
    },
    token: tokens.avax,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 52,
    lpSymbol: 'ZEC-WBNB',
    lpAddresses: {
      97: '',
      56: '0x2B2C771e44aF4C6f858598308e05FB89b23f11cE',
    },
    token: tokens.zec,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 53,
    lpSymbol: 'COTI-WBNB',
    lpAddresses: {
      97: '',
      56: '0xaCfDCF0486ADC2421aAc3FFc0923b9c56FAEBC47',
    },
    token: tokens.coti,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 54,
    lpSymbol: 'NEAR-WBNB',
    lpAddresses: {
      97: '',
      56: '0xB75724635A6cDa850f08b578F23a568CeDba099D',
    },
    token: tokens.near,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 55,
    lpSymbol: 'MOONLIGHT-WBNB',
    lpAddresses: {
      97: '',
      56: '0xe6de19Ae48969aF0a6f78271e41D3CE47580eaFB',
    },
    token: tokens.moonlight,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 56,
    lpSymbol: 'SNX-WBNB',
    lpAddresses: {
      97: '',
      56: '0x8b1F1F28a8CcbaA8a8Bc1582921ECe97Ce99d9e1',
    },
    token: tokens.snx,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 57,
    lpSymbol: 'BLZ-WBNB',
    lpAddresses: {
      97: '',
      56: '0x015f807d0186f7e62810D0C597A23CB19Ff57E4d',
    },
    token: tokens.blz,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 58,
    lpSymbol: 'TAPE-WBNB',
    lpAddresses: {
      97: '',
      56: '0x756d4406169273d99aAc8366cf5eAf7865d6a9b9',
    },
    token: tokens.tape,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 59,
    lpSymbol: 'CELR-WBNB',
    lpAddresses: {
      97: '',
      56: '0xB7F42e58Cf2364ac994F93f7afF3b158CFA3dC76',
    },
    token: tokens.celr,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 60,
    lpSymbol: 'SHIB-WBNB',
    lpAddresses: {
      97: '',
      56: '0xC0AFB6078981629F7eAe4f2ae93b6DBEA9D7a7e9',
    },
    token: tokens.shib,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 61,
    lpSymbol: 'CRUSH-WBNB',
    lpAddresses: {
      97: '',
      56: '0x8A10489f1255fb63217Be4cc96B8F4CD4D42a469',
    },
    token: tokens.crush,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 62,
    lpSymbol: 'PCWS-WBNB',
    lpAddresses: {
      97: '',
      56: '0x334E697022AeAbbA58385aFB3Abf3D9347C1b260',
    },
    token: tokens.pcws,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 63,
    lpSymbol: 'TYPH-WBNB',
    lpAddresses: {
      97: '',
      56: '0xFEaf192c2662E5700bDa860c58d2686d9cc12Ec8',
    },
    token: tokens.typh,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 64,
    lpSymbol: 'BMXX-WBNB',
    lpAddresses: {
      97: '',
      56: '0xcF2C3aF91B5A55E283a8A8C2932B88009B557b4a',
    },
    token: tokens.bmxx,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 65,
    lpSymbol: 'SPACE-WBNB',
    lpAddresses: {
      97: '',
      56: '0xd0F82498051067E154d1dcd3d88fA95063949D7e',
    },
    token: tokens.space,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 66,
    lpSymbol: 'FEG-WBNB',
    lpAddresses: {
      97: '',
      56: '0x73CDdF4ea34Dbd872f89e98c2866c81929AAFE50',
    },
    token: tokens.feg,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 67,
    lpSymbol: 'ATA-WBNB',
    lpAddresses: {
      97: '',
      56: '0x51DA890085da091b84e27c7A8234E371943b0af0',
    },
    token: tokens.ata,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 68,
    lpSymbol: 'GRAND-WBNB',
    lpAddresses: {
      97: '',
      56: '0x0c52721790387f97fA77aCAf151667C9e9730c76',
    },
    token: tokens.grand,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 69,
    lpSymbol: 'HIFI-WBNB',
    lpAddresses: {
      97: '',
      56: '0xf093cE6778c4d7D99C23f714297FFf15a661d354',
    },
    token: tokens.hifi,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 70,
    lpSymbol: 'GMR-WBNB',
    lpAddresses: {
      97: '',
      56: '0xB0D759CD87B74f079166283f4f6631F5703cEa1A',
    },
    token: tokens.gmr,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 71,
    lpSymbol: 'HERO-WBNB',
    lpAddresses: {
      97: '',
      56: '0x051724874952381E4efD22846B2789334D52AbDB',
    },
    token: tokens.hero,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 72,
    lpSymbol: 'TWIN-WBNB',
    lpAddresses: {
      97: '',
      56: '0x39ca344E2e9AAf125b0002aA37258C8b1Ed30A78',
    },
    token: tokens.twin,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 73,
    lpSymbol: 'MBOX-WBNB',
    lpAddresses: {
      97: '',
      56: '0xE5459c34E13797372f6c95c0aAC81A5fAf60223E',
    },
    token: tokens.mbox,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 74,
    lpSymbol: 'FOXY-WBNB',
    lpAddresses: {
      97: '',
      56: '0xbe3e38918Ca1180f0285fa18c3FA154d0ddE6853',
    },
    token: tokens.foxy,
    quoteToken: tokens.wbnb,
  },
  // {
  //   pid: 75,
  //   lpSymbol: 'POLYGON-FARM',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xA5818a82016cb07D0D9892736A2Abd1B47E78ea4',
  //   },
  //   token: tokens.wbnb,
  //   quoteToken: tokens.gnt,
  // },
  {
    pid: 76,
    lpSymbol: 'WYVERN-WBNB',
    lpAddresses: {
      97: '',
      56: '0xeef751Bba57E90B832c96b0E65ef5430868C69a7',
    },
    token: tokens.wyvern,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 77,
    lpSymbol: 'BOG-WBNB',
    lpAddresses: {
      97: '',
      56: '0x9D8370C3E6833942b8c38478c84ef74374F28b9f',
    },
    token: tokens.bog,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 78,
    lpSymbol: 'SCAM-WBNB',
    lpAddresses: {
      97: '',
      56: '0xaAB7b3C31c8f76e4bFe0D0Cd073b1bCa6279072c',
    },
    token: tokens.scam,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 79,
    lpSymbol: 'WBNB-LORY',
    lpAddresses: {
      97: '',
      56: '0x3d0c2Ee0156675B90Bc41E5559970415a20414F5',
    },
    token: tokens.lory,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 80,
    lpSymbol: 'FRUIT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x0bE55fd1Fdc7134Ff8412e8BAaC63CBb691B1d64',
    },
    token: tokens.fruit,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 81,
    lpSymbol: 'SKILL-WBNB',
    lpAddresses: {
      97: '',
      56: '0x0dEB588c1EC6f1D9f348126D401f05c4c7B7a80c',
    },
    token: tokens.skill,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 82,
    lpSymbol: 'GNT-WBNB',
    lpAddresses: {
      97: '',
      56: '0xE19C4B62eAb3b1b61C93c5Ddb27779c992413b0E',
    },
    token: tokens.gnt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 83,
    lpSymbol: 'SFP-WBNB',
    lpAddresses: {
      97: '',
      56: '0x6411A2240c8cD1dd48718eEE1ae4a10E71123Fd3',
    },
    token: tokens.sfp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 84,
    lpSymbol: 'TWT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x4c48D692e3de076C7b844B956b28cdd1DD5C0945',
    },
    token: tokens.twt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 85,
    lpSymbol: 'EPS-WBNB',
    lpAddresses: {
      97: '',
      56: '0x97c4C531e739E870d958940E8688017894084003',
    },
    token: tokens.eps,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 86,
    lpSymbol: 'XVS-WBNB',
    lpAddresses: {
      97: '',
      56: '0x9e199da6f87E09a290724EbA866eEdae2e971A0b',
    },
    token: tokens.xvs,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 87,
    lpSymbol: 'PACOCA-WBNB',
    lpAddresses: {
      97: '',
      56: '0x0fee6E1E55fA772fAE71E6734a7F9E8622900D75',
    },
    token: tokens.pacoca,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 88,
    lpSymbol: 'NEWB-WBNB',
    lpAddresses: {
      97: '',
      56: '0xF0cC208460BA9F55F320a72F6C6B63154A42c8C0',
    },
    token: tokens.newb,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 89,
    lpSymbol: 'BISON-WBNB',
    lpAddresses: {
      97: '',
      56: '0xEC1214Ee197304c17EB9e427E246a4Fd37BA718E',
    },
    token: tokens.bison,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 90,
    lpSymbol: 'YFI-WBNB',
    lpAddresses: {
      97: '',
      56: '0xA3421Bd2B3B1578FF43Ab95C10f667e5A3bbCeF7',
    },
    token: tokens.yfi,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 91,
    lpSymbol: 'STARS-WBNB',
    lpAddresses: {
      97: '',
      56: '0xBc8a4caD743d87e8754Fd5F704C62E378802CBfF',
    },
    token: tokens.stars,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 92,
    lpSymbol: 'SISTA-WBNB',
    lpAddresses: {
      97: '',
      56: '0x45546310FDA2fbDB7Ee26EA0A5b6F82D075254bC',
    },
    token: tokens.sista,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 93,
    lpSymbol: 'CAPS-WBNB',
    lpAddresses: {
      97: '',
      56: '0x6Dfbf17ac70CE03388B1f88cb3C97AD79120e7b1',
    },
    token: tokens.caps,
    quoteToken: tokens.wbnb,
  },
   {
    pid: 94,
    lpSymbol: 'BIRB-WBNB',
    lpAddresses: {
      97: '',
      56: '0x8F53e5940d5ADFB07e271d2812DCcdb5B6380C62',
    },
    token: tokens.birb,
    quoteToken: tokens.wbnb,
  },
   {
    pid: 95,
    lpSymbol: 'AXS-WBNB',
    lpAddresses: {
      97: '',
      56: '0x8b85A4228400fa9b2fB5bD47dB8f05b7f8Bb7F5c',
    },
    token: tokens.axs,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 96,
    lpSymbol: 'DINOP-WBNB',
    lpAddresses: {
      97: '',
      56: '0x5fCec12f1C7e57789f22289Ef75FBDB1c6b4895d',
    },
    token: tokens.dinop,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 97,
    lpSymbol: 'MARU-WBNB',
    lpAddresses: {
      97: '',
      56: '0x172a5434366795ccDF755ffBf0cc04D4532A7177',
    },
    token: tokens.maru,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 98,
    lpSymbol: 'PERA-WBNB',
    lpAddresses: {
      97: '',
      56: '0xB3940bCF6e37DD612B8Dee72ADA6cf8dF57d8A95',
    },
    token: tokens.pera,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 99,
    lpSymbol: 'GUARD-WBNB',
    lpAddresses: {
      97: '',
      56: '0xDd2B5E024942F9a83255F41144db5648b71f01c4',
    },
    token: tokens.guard,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 100,
    lpSymbol: 'LAND-WBNB',
    lpAddresses: {
      97: '',
      56: '0xB15f34082Baa4E3515A49E05D4d1D40cE933da0b',
    },
    token: tokens.land,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 101,
    lpSymbol: 'POTS-WBNB',
    lpAddresses: {
      97: '',
      56: '0xaa3FCBA2cf7c5f8c8f785A7180F5063144fE53c6',
    },
    token: tokens.pots,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 102,
    lpSymbol: 'SACT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x8418E3D34d42ca93FAA77a9Ff7D0d82b811b4633',
    },
    token: tokens.sact,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 103,
    lpSymbol: 'BHC-WBNB',
    lpAddresses: {
      97: '',
      56: '0x59B2A38F67e312D312121614d1Daa7ad8c5773fC',
    },
    token: tokens.bhc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 104,
    lpSymbol: 'CYT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x5a55Fce6F6aCB2B1b20514964301Db4f37028C81',
    },
    token: tokens.cyt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 105,
    lpSymbol: 'HOTCROSS-BUSD',
    lpAddresses: {
      97: '',
      56: '0x3B9Aa711D1D90A4f8639f66C227881729a3317F2',
    },
    token: tokens.hotcross,
    quoteToken: tokens.busd,
  },
  {
    pid: 106,
    lpSymbol: 'LMT-WBNB',
    lpAddresses: {
      97: '',
      56: '0xbAC4313d7F908C9A657B58B5b42F0f7713A3990b',
    },
    token: tokens.lmt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 107,
    lpSymbol: 'FOOT-WBNB',
    lpAddresses: {
      97: '',
      56: '0x178Fc55C4E167ecAEAAC450909fa5Bc60EC92BAF',
    },
    token: tokens.foot,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 108,
    lpSymbol: 'NABOX-WBNB',
    lpAddresses: {
      97: '',
      56: '0x7C35E27c6dC847EA720C435aBbe30bdd29168581',
    },
    token: tokens.nabox,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 109,
    lpSymbol: 'BABL-WBNB',
    lpAddresses: {
      97: '',
      56: '0x07999087e34Fa79E7145C051Ac4b1aE9407beFf4',
    },
    token: tokens.babl,
    quoteToken: tokens.wbnb,
  },
]

export default farms_ape
