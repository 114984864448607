import Web3 from 'web3'
import { Interface } from '@ethersproject/abi'
import web3NoAccount,{web3NoAccount_mainnet} from './web3'
import { getMulticallContract,getMulticallMainnetContract } from './contractHelpers'


interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

interface MulticallOptions {
  web3?: Web3
  blockNumber?: number
  requireSuccess?: boolean
}

const multicall = async (abi: any[], calls: Call[], options: MulticallOptions = {}) => {
  try {
    const multi = getMulticallContract(options.web3 || web3NoAccount)
    const itf = new Interface(abi)
    

    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    //console.log('calldata1',calldata)
    const { returnData } = await multi.methods.aggregate(calldata).call(undefined, options.blockNumber)
   // console.log("multicall",returnData)
  
    const res = returnData.map((call, i) => {
     
      return itf.decodeFunctionResult(calls[i].name, call)})

    return res
  } catch (error) {
    throw new Error(error)
  }
}
export const multicall_mainnet = async (abi: any[], calls: Call[], options: MulticallOptions = {}) => {
  try {
   
    const multi = getMulticallMainnetContract(options.web3 || web3NoAccount_mainnet)
    const itf = new Interface(abi)
    

    const calldata = calls.map((call) => {
     
      if(!call.address){
        console.log("call_undefine",call)
        return 
      }
      return [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)]
    })
  console.log("calldata",calldata)
    const { returnData } = await multi.methods.aggregate(calldata).call(undefined, options.blockNumber)
 //console.log("multicall",returnData)
   
    const res = returnData.map((call, i) => {
     // console.log("multicall_mainnet",calls[i].address+"|||"+call)
      return itf.decodeFunctionResult(calls[i].name, call)})

    return res
  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

/**
 * Multicall V2 uses the new "tryAggregate" function. It is different in 2 ways
 *
 * 1. If "requireSuccess" is false multicall will not bail out if one of the calls fails
 * 2. The return inclues a boolean whether the call was successful e.g. [wasSuccessfull, callResult]
 */
export const multicallv2 = async (abi: any[], calls: Call[], options: MulticallOptions = {}): Promise<any> => {
  const multi = getMulticallContract(options.web3 || web3NoAccount)
  const itf = new Interface(abi)
  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  const returnData = await multi.methods
    .tryAggregate(options.requireSuccess === undefined ? true : options.requireSuccess, calldata)
    .call(undefined, options.blockNumber)
  const res = returnData.map((call: any, i: any) => {
    const [result, data] = call
    return result ? itf.decodeFunctionResult(calls[i].name, data) : null
  })

  return res
}

export default multicall
